<!--模板4 正确率（步骤）-->
<template>
  <div class="answer-template-4">
    <!--左侧-->
    <div class="answer-template-4-l">
      <ul class="panel">
        <!--素材-->
        <div class="material">
          <div class="material-left">
            <div style="font-size: 12px">
              <span style="color: #3a5ff8"> {{ activeMaterial.resultList.length }}</span
              >/{{ data.minimumRounds }}
            </div>
            <div style="font-size: 14px">
              {{ activeMaterial.name }}
            </div>
          </div>
          <div class="material-revoke">
            <a-button type="link" @click="handleRevoke" :class="[!isRevoke ? 'revoke-allow' : '']" :disabled="isRevoke"
              >撤销</a-button
            >
          </div>
        </div>
        <li v-for="(item, index) in activeMaterial.stepsDescriptionList" :key="index + '1'">
          <div class="panel-content">
            <div :class="index === active.left ? 'active' : ''" class="panel-content-left">
              <div class="steps-item">
                {{ item }}
              </div>
            </div>
            <!--操作按钮-对错-->
            <div class="panel-content-right">
              <img
                v-show="index === active.left"
                src="~@/assets/icons/right-default@2x.png"
                alt=""
                @click="handleAddOrSub('true')"
              />
              <img
                v-show="index === active.left"
                src="~@/assets/icons/wrong-default@2x.png"
                alt=""
                @click="handleAddOrSub('false')"
              />
            </div>
          </div>
          <!--最近10回合记录-->
          <div class="last-ten-rounds">
            最近10回合记录：
            <span
              class="rounds-item"
              v-for="(third, third_index) in tenRound({ historyList, index })"
              :key="third_index + '2'"
            >
              <template v-if="[third].indexOf('true') > -1">
                <a-icon type="check" :style="{ color: '#52BE7F', fontSize: '17px' }" />
              </template>
              <template v-else-if="[third].indexOf('false') > -1">
                <a-icon type="close" :style="{ color: '#F12A2C', fontSize: '17px' }" />
              </template>
              <template v-else>-</template>
            </span>
          </div>
        </li>
      </ul>
    </div>
    <!--右侧-->
    <div class="answer-template-4-r answer-template-r">
      <div
        v-for="(item, index) in data.materialList"
        :key="index"
        :class="index === active.right ? 'active' : ''"
        @click="handleChooseRight({ item, index })"
      >
        <p>
          <span style="color: #3a5ff8">{{ trainedRounds(item) }}</span
          >/{{ data.minimumRounds }}
        </p>
        <p>{{ item.name }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { cloneDeep, zip } from 'lodash'
import { mapActions } from 'vuex'
export default {
  props: {
    current: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      active: {
        left: 0,
        right: 0
      },
      // 临时存当前回合数据
      tempResultList: [],
      // 临时时间数据
      tempTimeList: [],
      // 当前选中的素材
      activeMaterial: {},
      data: {},
      // 最后几回合
      lastRounds: 10,
      // 历史对错列表
      historyList: []
    }
  },
  created() {
    this.data = cloneDeep(this.current)
    const { childData } = cloneDeep(this.current)
    if (childData) {
      const { active, tempResultList, tempTimeList, historyList } = childData
      this.active = active
      this.tempResultList = tempResultList
      this.tempTimeList = tempTimeList
      this.activeMaterial = this.data.materialList[active.right]
      this.historyList = historyList
      delete this.data.childData
    } else {
      this.activeMaterial = this.data.materialList[this.active.right]
    }
    if (!this.tempTimeList.length) {
      this.tempTimeList.push({ start: new Date().getTime(), end: 0 })
    }
  },
  computed: {
    // 撤销是否禁用
    isRevoke() {
      const { resultList } = this.activeMaterial
      if (!resultList || (resultList.length === 0 && this.tempResultList.length === 0)) {
        return true
      }
      return false
    }
  },
  methods: {
    // 最近10回合记录
    tenRound({ historyList, index }) {
      const stepRound = cloneDeep(historyList[index])
      // 已经训练过
      if (stepRound) {
        let temp = []
        // 训练未达到 lastRounds, 会出现null,过滤掉假值后对比长度
        if (stepRound.filter(Boolean).length < this.lastRounds) {
          // 当前步骤已经训练
          if (this.tempResultList[index] && this.tempResultList[index] !== null) {
            // console.log('当前步骤已经训练')
            if (stepRound.filter(Boolean).length < this.lastRounds) {
              temp = stepRound
            } else {
              temp = [...stepRound.filter(Boolean), this.tempResultList[index]]
            }
          } else {
            // 未训练 不动
            temp = stepRound
          }
        } else {
          // 训练达到 lastRounds后, 不会出现null
          if (this.activeMaterial.resultList.length >= this.lastRounds) {
            // 已经训练的步骤, 取第2个到末尾+训练过的1个步骤
            if (['true', 'false'].indexOf(this.tempResultList[index]) > -1) {
              temp = [...stepRound.slice(1, stepRound.length), ...[this.tempResultList[index]]]
            } else {
              temp = stepRound
            }
          } else {
            temp = stepRound
          }
        }

        const trainList = cloneDeep(temp).filter(Boolean)
        if (trainList.length >= this.lastRounds) {
          return trainList
        } else {
          return [...trainList, ...new Array(this.lastRounds - trainList.length)]
        }
      } else {
        // 未训练过,返回空
        return new Array(this.lastRounds)
      }
    },
    ...mapActions(['changeSpinning']),
    // 选切换右侧
    handleChooseRight({ index }) {
      // 暂存有数据 存起来
      if (this.tempResultList.length) {
        this.activeMaterial.resultList.push(this.tempResultList)
        // 删掉最后一个
        this.tempTimeList.pop()
        this.activeMaterial.timeList.push(this.tempTimeList)
      }

      // 重置
      this.active.right = index
      // 取新素材
      this.activeMaterial = this.data.materialList[this.active.right]
      // 取出答题数据
      const { resultList, timeList, stepsDescriptionList } = this.activeMaterial

      // 从未答过
      if (resultList.length === 0) {
        this.tempResultList = []
        this.tempTimeList = [{ start: new Date().getTime(), end: 0 }]
        this.active.left = 0
      }

      //  答过题 (取上一轮最后一个数组，判断上次是否完成一轮)
      if (resultList.length > 0) {
        // 上次最后一轮数据
        const lastResultList = resultList.pop()
        const lastTimeList = timeList.pop()
        // 上一轮已完成
        if (lastResultList.length === stepsDescriptionList.length) {
          this.activeMaterial.resultList.push(lastResultList)
          this.activeMaterial.timeList.push(lastTimeList)
          this.tempResultList = []
          this.tempTimeList.push({ start: new Date().getTime(), end: 0 })
          this.active.left = 0
        }
        // 上一轮未完成
        if (lastResultList.length < stepsDescriptionList.length) {
          this.tempResultList = lastResultList
          this.tempTimeList = lastTimeList
          this.tempTimeList.push({ start: new Date().getTime(), end: 0 })
          this.active.left = lastResultList.length
        }
      }

      this.calcLastRounds()
    },
    // 撤销
    handleRevoke() {
      // 无暂存，获取上次最后一条扔到暂存
      if (this.tempResultList.length === 0) {
        this.tempResultList = this.activeMaterial.resultList.pop()
        this.tempTimeList = this.activeMaterial.timeList.pop()
        this.active.left = this.tempResultList.length
      }
      // 索引-1
      this.active.left--
      // 删掉最后一个
      this.tempResultList.splice(this.tempResultList.length - 1, 1)
      // 撤销大于1，删掉最后一个
      if (this.tempTimeList.length !== 1) {
        this.tempTimeList = this.tempTimeList.slice(0, this.active.left + 1)
      }
      // 重置剩余最后一个起、止时间
      this.tempTimeList[this.tempTimeList.length - 1].start = new Date().getTime()
      this.tempTimeList[this.tempTimeList.length - 1].end = 0
      // 撤销到 显示第一个 后台发数据
      if (this.active.left === 0) {
        this.saveClassTraining()
      }

      this.calcLastRounds()
    },
    // 操作 对 | 错
    handleAddOrSub(flag) {
      this.active.left++
      this.tempResultList.push(String(flag))
      // 临时数据（上一个结束时间===下一个开始时间）
      const time = new Date().getTime()
      this.tempTimeList[this.tempTimeList.length - 1].end = time
      if (this.active.left === this.activeMaterial.stepsDescriptionList.length) {
        this.activeMaterial.resultList.push(this.tempResultList)
        this.activeMaterial.timeList.push(this.tempTimeList)
        this.active.left = 0
        this.tempResultList = []
        this.tempTimeList = [{ start: new Date().getTime(), end: 0 }]
      } else {
        this.tempTimeList.push({ start: time, end: 0 })
      }

      // 每次都是 显示第一个 后台发数据
      if (this.active.left === 0) {
        this.saveClassTraining()
      }
      this.calcLastRounds()
    },
    // 计算最后lastRounds回合
    calcLastRounds() {
      const { resultList } = cloneDeep(this.activeMaterial)
      const tempLastRounds = resultList.length > this.lastRounds ? resultList.slice(-this.lastRounds) : resultList
      const activeResultList = this.tempResultList

      let lastRound = resultList.length > this.lastRounds - 1 ? cloneDeep(tempLastRounds[this.lastRounds - 1]) : []
      let arr = []
      // 历史答案不足lastRounds回合,push
      if (tempLastRounds.length < this.lastRounds) {
        lastRound = activeResultList
        arr = [...tempLastRounds, lastRound]
      } else {
        if (this.active.left === 0) {
          lastRound = [...activeResultList, ...lastRound]
        }
        arr = [...tempLastRounds]
      }
      this.historyList = zip(...arr)
    },

    // 保存训练时间
    saveClassTraining() {
      const { phaseId: planSkillPhaseId, materialList } = this.data
      // 临时变量
      const classTrainingList = []
      // 遍历素材,拼装数据
      for (const item of materialList) {
        const { resultList = [], name: material, id: materialId, timeList = [] } = item
        resultList &&
          resultList.forEach((item1, index1) => {
            // 过滤掉未完成一回合
            if (item1.length === this.activeMaterial.stepsDescriptionList.length) {
              const tempClassTraining = {
                resultList: item1,
                material,
                materialId,
                round: index1 + 1,
                trainingDuration: 0
              }
              // 计算总秒数
              timeList[index1].forEach(item2 => {
                const { start, end } = item2
                tempClassTraining.trainingDuration += Math.ceil((end - start) / 1000) //  向上取整(业务需求)
              })
              classTrainingList.push(tempClassTraining)
            }
          })
      }

      const params = {
        classTrainingList,
        planSkillPhaseId
      }
      this.$emit('saveClassData', params)
    },

    // 已训练回合数
    trainedRounds(item) {
      const { resultList, stepsDescriptionList } = item
      let round = 0
      // eslint-disable-next-line no-unused-expressions
      resultList && resultList.length
        ? resultList.map(item1 => {
            if (item1.length === stepsDescriptionList.length) {
              round++
            }
          })
        : 0
      return round
    },

    // 返回数据
    getData() {
      const childData = {
        active: this.active,
        tempResultList: this.tempResultList,
        tempTimeList: this.tempTimeList,
        historyList: this.historyList
      }
      const _data = cloneDeep(this.data)
      this.$set(_data.materialList, this.active.right, this.activeMaterial)
      return Object.assign({}, _data, { childData })
    }
  }
}
</script>
<style lang="less" scoped>
.answer-template-4 {
  display: flex;
  .answer-template-4-l {
    padding-top: 18px;
    flex: 1;
    display: flex;
    flex-direction: column;
    .panel {
      padding-bottom: 32px;
      padding-left: 0;
      /*素材*/
      .material {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 24px;
        .material-left {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          width: 150px;
          height: 64px;
          background: #e1e8ff;
          font-size: 16px;
          font-weight: 400;
          color: #1a1a1a;
        }
        .material-revoke {
          margin-left: 17px;
          font-size: 20px;
          .revoke-allow {
            color: #f12a2c;
          }
        }
      }
      li {
        margin-bottom: 24px;
        .panel-content {
          display: flex;
          // padding-left: 140px;
          margin-bottom: 8px;
          .panel-content-left {
            flex: 1;
            height: 54px;
            border-radius: 4px;
            color: #595959;
            border: 1px solid #dfe0ea;
            .steps-item {
              width: 100%;
              height: 100%;
              padding: 5px;
              display: flex;
              align-items: center;
              justify-content: center;
              word-wrap: break-word;
              word-break: break-all;
              overflow-y: auto;
            }
          }

          .panel-content-right {
            display: flex;
            align-items: center;
            width: 108px;
            /*padding-left: 10px;*/
            img {
              width: 32px;
              height: 32px;
              margin-left: 16px;
              cursor: pointer;
            }
          }

          .active {
            background: #e1e8ff;
            border: 1px solid #e1e8ff;
          }
        }
        .last-ten-rounds {
          // padding-left: 163px;
          display: flex;
          justify-content: flex-start;
          color: #8c8c8c;
          .rounds-item {
            display: inline-block;
            width: 40px;
            text-align: center;
            padding: 0 8px;
            border-right: 1px solid #dfe0ea;
          }
        }
      }
    }
  }
  .answer-template-r {
    width: 160px;
    padding-left: 10px;
    background: #f7f8fc;
    div {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 64px;
      background: #ffffff;
      border: 1px solid #dfe0ea;
      border-radius: 4px;
      font-size: 14px;
      color: #595959;
      margin-bottom: 8px;
      cursor: pointer;
      margin-right: 5px;
      p {
        text-align: center;
        font-size: 14px;
        color: #595959;
        margin-bottom: 0;
      }
    }
    .active {
      background: #e1e8ff;
      border-radius: 4px;
      border: 1px solid #e1e8ff;
    }
  }
}

/*定义滚动条高宽及背景
高宽分别对应横竖滚动条的尺寸*/
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  background-color: rgba(0, 0, 0, 0.08);
}
/*定义滚动条轨道
 内阴影+圆角*/
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  background-color: rgba(0, 0, 0, 0);
}
/*定义滑块
 内阴影+圆角*/
::-webkit-scrollbar-thumb {
  border-radius: 16px;
  -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0.08);
  background-color: rgba(144, 147, 153, 0.3);
}
</style>
