<!--计时计次-->
<template>
  <div class="timing-count">
    <div class="top">
      <a-tag color="#E1E8FF" :title="data.name" class="top-l" style="color: #333; border-radius: 2px">
        {{ data.name }}
      </a-tag>
      <a-badge class="top-r" :count="historyRound" title="本堂课该行为记录次数" />
    </div>
    <a-row class="center">
      <div class="center-l" title="计时计次">
        <img src="~@/assets/icons/signal.png" alt="" />
        <img src="~@/assets/icons/clock1.png" alt="" />
      </div>
      <div class="center-r">
        <a-col :span="24" style="display: flex; justify-content: space-around">
          <a-button type="primary" shape="circle" @click="handleDel" :disabled="count < 1 || btnFlag">-</a-button>
          <span>{{ count }}</span>
          <a-button type="primary" shape="circle" @click="handleAdd" :disabled="btnFlag">+</a-button>
        </a-col>
        <a-col :span="24">{{ showTime }}</a-col>
      </div>
    </a-row>
    <a-row class="bottom">
      <a-tag color="#3a5ff8" @click="handleSrart" v-if="btnFlag">开始</a-tag>
      <a-tag color="#F8A70E" @click="handleFinish" v-else>结束</a-tag>
    </a-row>
  </div>
</template>
<script>
import { cloneDeep } from 'lodash'
export default {
  props: {
    tempList: {
      type: Array,
      default: () => {
        return []
      }
    },
    data: {
      type: Object,
      default: () => {
        return {}
      }
    },
    index: {
      type: Number,
      default: -1
    }
  },
  data() {
    return {
      count: 0,
      btnFlag: true, // 按钮显示
      totalSeconds: 0, // 总秒数
      timer: null // 定时器
    }
  },
  computed: {
    showTime() {
      let minute = '00'
      let second = '00'
      if (this.totalSeconds < 60) {
        second = this.formatTime(this.totalSeconds)
      } else {
        minute = this.formatTime(parseInt(this.totalSeconds / 60))
        second = this.formatTime(parseInt(this.totalSeconds % 60))
      }
      return `${minute} : ${second}`
    },
    historyRound() {
      const { tempList, data } = this
      return tempList
        ? tempList.filter(item => {
            return item.behaviorRecordId === data.id
          }).length
        : 0
    }
  },

  methods: {
    // 开始按钮
    handleSrart() {
      this.btnFlag = !this.btnFlag
      this.timer = setInterval(() => {
        this.totalSeconds++
      }, 1000)
    },
    // 结束按钮
    handleFinish() {
      clearInterval(this.timer)
      this.btnFlag = !this.btnFlag
      this.$emit('finish', {
        item: cloneDeep(this.data),
        index: this.index,
        duration: this.totalSeconds,
        frequency: this.count
      })

      this.$nextTick(() => {
        this.initData()
      })
    },
    // 格式化数字 往前补0
    formatTime(number) {
      return number < 10 ? `0${number}` : number
    },

    // 加
    handleAdd() {
      this.count++
    },

    // 减
    handleDel() {
      this.count > 0 && this.count--
    },
    // 初始化数据
    initData() {
      this.totalSeconds = 0
      this.btnFlag = true
      this.count = 0
    }
  }
}
</script>
<style scoped lang="less">
.timing-count {
  height: 100%;
  .top {
    position: relative;
    display: flex;
    justify-content: flex-start;
    padding-bottom: 5px;
    .top-l {
      overflow: hidden;
    }
    .top-r {
      position: absolute;
      top: -15px;
      right: -13px;
    }
  }
  .center {
    display: flex;
    .center-l {
      img {
        display: block;
        width: 20px;
        height: 20px;
        margin-right: 5px;
      }
    }
    .center-r {
      flex: 1;
    }
    /deep/ .ant-col {
      text-align: center;
    }
    /deep/ .ant-btn {
      width: 16px;
      height: 16px;
      min-width: 0;
      line-height: 16px;
      overflow: hidden;
      > span {
        position: relative;
        top: -2px;
      }
    }
  }
  .bottom {
    position: absolute;
    bottom: 5px;
    right: 0;
  }
}
</style>
