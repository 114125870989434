import request from '@/utils/request'
const servicemgrApi = {
  list: '/service',
  create: '/service',
  update: id => `/service/${id}`,
  detail: id => `/service/${id}`,
  remove: id => `/service/${id}`,
  skillList: '/v3/teaching/practice-skills',
  getBehaviorRecords: id => `/v3/behavior-records/student/${id}`,
  markBehaviorRecords: `/v3/behavior-records/mark`,
  addBehaviorRecords: `/v3/behavior-records/add`,
  deleteBehaviorRecords: (behaviorRecordId, studentId) =>
    `/v3/behavior-records/student/${studentId}/behavior-record/${behaviorRecordId}`,
  getTeachingId: studentId => `/v3/teaching/student/${studentId}/start-teaching`,
  getTeachingDetal: teachingClassId => `/v3/teaching/teaching/date/teaching-class/${teachingClassId}`,
  getTeachingReport: teachingId => `/v3/teaching/details/teaching/${teachingId}`,
  saveClassTraining: `/v3/teaching/training`,
  saveClassBehaviorRecord: `/v3/teaching/class-behaviors`,
  deleteClassBehaviorRecord: teachingId => `/v3/teaching/behaviors/teaching/${teachingId}`,
  finishTeaching: teachingClassId => `/v3/teaching/teaching/${teachingClassId}/finish-teaching`,
  finishComments: teachingId => `/v3/teaching/teaching/${teachingId}/comments`,
  getStudentTeachingDateList: studentId => `/v3/teaching/students/${studentId}/teaching-date`,
  getStudentTeachingDateTimeList: (studentId, teachingDate) => `/v3/teaching/student/${studentId}/date/${teachingDate}`,
  homeSchoolDetail: studentId => `/student/homeSchool/students/${studentId}`,
  classBeginRealTimeSave: `/v3/teaching/real-save`, // 上课-实时保存
  classRecordDetail: teachingId => `/v3/teaching/record/details/teaching-class-id/teaching/${teachingId}` // 上课记录-详情
}

// 上课记录-详情
export function classRecordDetail(parameter) {
  const {
    teachingId
  } = parameter
  return request({
    url: servicemgrApi.classRecordDetail(teachingId),
    method: 'get'
  })
}

// 家校训练服务-列表详情（单条数据）
export function homeSchoolDetail(parameter) {
  const {
    studentId
  } = parameter
  return request({
    url: servicemgrApi.homeSchoolDetail(studentId),
    method: 'get'
  })
}

// 家校训练服务-详情-上课记录-上课日期
export function getStudentTeachingDateList(parameter) {
  const {
    studentId
  } = parameter
  return request({
    url: servicemgrApi.getStudentTeachingDateList(studentId),
    method: 'get'
  })
}

// 家校训练服务-详情-上课记录-上课条目
export function getStudentTeachingDateTimeList(parameter) {
  const {
    studentId,
    teachingDate
  } = parameter
  return request({
    url: servicemgrApi.getStudentTeachingDateTimeList(studentId, teachingDate),
    method: 'get'
  })
}

// 家校训练服务-上课-保存评语
export function finishComments(parameter) {
  const {
    id: teachingId,
    comments
  } = parameter
  return request({
    url: servicemgrApi.finishComments(teachingId),
    method: 'post',
    data: {
      comments
    }
  })
}

// 家校训练服务-上课-结束上课
export function finishTeaching(parameter) {
  const {
    teachingClassId
  } = parameter
  return request({
    url: servicemgrApi.finishTeaching(teachingClassId),
    method: 'post',
    data: parameter
  })
}

// 上课-实时保存
export function classBeginRealTimeSave(parameter) {
  return request({
    url: servicemgrApi.classBeginRealTimeSave,
    method: 'post',
    data: parameter
  })
}

// 删除上课-行为记录
export function deleteClassBehaviorRecord(parameter) {
  const {
    teachingId,
    classBehaviorRecordList
  } = parameter
  return request({
    url: servicemgrApi.deleteClassBehaviorRecord(teachingId),
    method: 'delete',
    data: classBehaviorRecordList
  })
}

// 家校训练服务-上课-选择训练目标-保存训练素材记录（A区）
export function saveClassBehaviorRecord(parameter) {
  return request({
    url: servicemgrApi.saveClassBehaviorRecord,
    method: 'put',
    data: parameter
  })
}

// 家校训练服务-上课-选择训练目标-保存训练素材记录（c区）
export function saveClassTraining(parameter) {
  return request({
    url: servicemgrApi.saveClassTraining,
    method: 'post',
    data: parameter
  })
}

// 家校训练服务-上课-选择训练目标-上课信息
export function getTeachingDetal(parameter) {
  const {
    teachingClassId
  } = parameter
  return request({
    url: servicemgrApi.getTeachingDetal(teachingClassId),
    method: 'get'
  })
}

// 家校训练服务-上课-选择训练目标-结束上课之后
export function getTeachingReport(parameter) {
  const {
    teachingId
  } = parameter
  return request({
    url: servicemgrApi.getTeachingReport(teachingId),
    method: 'get'
  })
}

// 家校训练服务-上课-选择训练目标-列表
export function getSkillList(parameter) {
  return request({
    url: servicemgrApi.skillList,
    method: 'get',
    params: parameter
  })
}

// 家校训练服务-上课-选择训练目标-保存
export function saveSkillList(parameter) {
  return request({
    url: servicemgrApi.skillList,
    method: 'post',
    data: parameter
  })
}

// 家校训练服务-上课-设置行为记录-获取
export function getBehaviorRecords(parameter) {
  const {
    id
  } = parameter
  return request({
    url: servicemgrApi.getBehaviorRecords(id),
    method: 'get'
  })
}

// 家校训练服务-上课-设置行为记录-标记
export function markBehaviorRecords(parameter) {
  return request({
    url: servicemgrApi.markBehaviorRecords,
    method: 'post',
    data: parameter
  })
}

// 家校训练服务-上课-设置行为记录-删除
export function deleteBehaviorRecords(parameter) {
  const {
    behaviorRecordId,
    studentId
  } = parameter
  return request({
    url: servicemgrApi.deleteBehaviorRecords(behaviorRecordId, studentId),
    method: 'delete'
  })
}

// 家校训练服务-上课-设置行为记录-添加
export function addBehaviorRecords(parameter) {
  return request({
    url: servicemgrApi.addBehaviorRecords,
    method: 'post',
    data: parameter
  })
}

// 家校训练服务-上课-设置行为记录-获取上课id
export function getTeachingId(parameter) {
  const {
    studentId
  } = parameter
  delete parameter.studentId
  return request({
    url: servicemgrApi.getTeachingId(studentId),
    method: 'post',
    params: parameter
  })
}

export function list(parameter) {
  return request({
    url: servicemgrApi.list,
    method: 'get',
    params: parameter
  })
}
export function create(parameter) {
  return request({
    url: servicemgrApi.create,
    method: 'post',
    data: parameter
  })
}
export function update(parameter) {
  const {
    id
  } = parameter
  delete parameter.id
  return request({
    url: servicemgrApi.update(id),
    method: 'put',
    data: parameter
  })
}
export function detail(parameter) {
  return request({
    url: servicemgrApi.detail(parameter),
    method: 'get'
  })
}
export function remove(parameter) {
  return request({
    url: servicemgrApi.remove(parameter),
    method: 'delete'
  })
}