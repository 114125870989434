<!--模板1 正确率回合-->
<template>
  <div class="answer-template-1">
    <div class="head">
      <div>最近{{ lastRounds }}回合记录</div>
      <div class="material">训练素材</div>
      <div class="revoke"></div>
      <div>数据记录</div>
    </div>
    <div class="panel" v-for="(materialItem, index) in data.materialList" :key="'materialItem' + String(index)">
      <div class="last-ten-rounds">
        <span
          v-for="(item1, index1) in materialItem.resultList.slice(-lastRounds)"
          :key="'item1' + String(index1)"
          class="steps-item"
        >
          <a-icon v-if="item1 === 'true'" type="check" :style="{ color: '#52BE7F', fontSize: '17px' }" />
          <a-icon v-else-if="item1 === 'false'" type="close" :style="{ color: '#F12A2C', fontSize: '17px' }" />
          <span v-else>{{ item1 }}</span>
        </span>
        <span v-for="empty_index in calcEmptyRecord(materialItem)" :key="'empty_index' + empty_index" class="steps-item"
          >-</span
        >
      </div>
      <div class="material-name">
        <div>{{ materialItem.name }}</div>
      </div>
      <div class="revoke">
        <span :class="[finalTrainMaterial === materialItem.id ? 'trained-round' : '']">{{
          materialItem.resultList.length
        }}</span
        >/{{ data.minimumRounds }}
        <a-button
          type="link"
          :class="[materialItem.resultList.length ? 'revoke-allow' : '']"
          :disabled="!materialItem.resultList.length"
          @click="handleRevoke({ index })"
          >撤销</a-button
        >
      </div>
      <div class="data-record">
        <a-button
          class="method-button"
          v-for="(method, method_index) in data.trainingAssistanceModeList"
          :key="'method_index' + method_index"
          @click="handleAddSub({ buttonType: method.id, index, materialItem })"
          :class="tempMethod.indexOf(`${materialItem.id}${method.id}`) > -1 ? 'method-button-flash' : ''"
          style="margin-bottom: 8px"
        >
          {{ method.id }}
        </a-button>
        <img
          src="~@/assets/icons/wrong-click@2x.png"
          alt=""
          v-if="tempMethod.indexOf(`${materialItem.id}false`) > -1"
        />
        <img
          v-else
          src="~@/assets/icons/wrong-default@2x.png"
          alt=""
          @click="handleAddSub({ buttonType: 'false', index, materialItem })"
        />

        <img src="~@/assets/icons/right-click@2x.png" alt="" v-if="tempMethod.indexOf(`${materialItem.id}true`) > -1" />
        <img
          v-else
          src="~@/assets/icons/right-default@2x.png"
          alt=""
          @click="handleAddSub({ buttonType: 'true', index, materialItem })"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash'
import { mapActions } from 'vuex'

export default {
  props: {
    current: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      data: {},
      lastRounds: 10, // 最近回合数
      tempMethod: [], // 临时存储点击的PP123
      finalTrainMaterial: '' // 最后一次训练的素材(回合显示蓝色)
    }
  },
  created() {
    this.data = cloneDeep(this.current)
    const { childData } = cloneDeep(this.current)
    if (childData && childData?.tempMethod && childData?.finalTrainMaterial) {
      const { tempMethod, finalTrainMaterial } = childData
      this.finalTrainMaterial = finalTrainMaterial
      this.tempMethod = tempMethod
      delete this.data.childData
    }
  },
  methods: {
    ...mapActions(['changeSpinning']),

    // 撤销
    handleRevoke({ index }) {
      const { resultList } = this.data.materialList[index]
      // 删掉数组最后一个
      resultList.pop()

      this.saveClassTraining()
    },
    // 加、减、PP123
    handleAddSub({ buttonType, materialItem, index }) {
      this.data.materialList[index].resultList.push(buttonType)
      const { id: materiaId } = materialItem
      // 点PP123、对、错（需求是隔一段时间选中状态消失）
      this.tempMethod.push(`${materiaId}${buttonType}`)
      // 最后一次操作的素材
      this.finalTrainMaterial = materiaId
      this.saveClassTraining()
    },

    // 保存训练时间
    saveClassTraining() {
      const { phaseId: planSkillPhaseId, materialList } = this.data
      // frequency 第3种情况 才传
      // 临时变量
      const classTrainingList = []

      // 遍历素材列表
      for (const item of materialList) {
        const { resultList = [], name: material, id: materialId } = item
        resultList &&
          resultList.forEach((item1, index1) => {
            const tempClassTraining = {
              resultList: new Array(item1),
              material,
              materialId,
              round: index1 + 1,
              trainingDuration: 0 //  向上取整(业务需求)
            }
            classTrainingList.push(tempClassTraining)
          })
      }
      const params = {
        classTrainingList,
        planSkillPhaseId
      }
      this.$emit('saveClassData', params)
    },
    // 返回数据
    getData() {
      const { finalTrainMaterial } = this
      return cloneDeep({ ...this.data, ...{ childData: { finalTrainMaterial } } })
    },
    // 计算空记录
    calcEmptyRecord(item) {
      const { resultList } = item
      const { lastRounds } = this
      return lastRounds - resultList.slice(-lastRounds).length
    },

    // 按点击顺序移除选中按钮状态
    removeTempMethod() {
      clearTimeout(this.timer)
      if (this.tempMethod.length > 0) {
        this.timer = setTimeout(() => {
          this.tempMethod.shift()
          this.removeTempMethod()
        }, 300)
      } else {
        this.timer = null
      }
    }
  },
  watch: {
    tempMethod(newVal) {
      if (newVal.length) {
        !this.timer && this.removeTempMethod()
      }
    }
  }
}
</script>
<style lang="less" scoped>
.answer-template-1 {
  padding-top: 18px;
  // 头
  .head {
    padding: 0 24px;
    display: flex;
    height: 44px;
    background: #f7f8fc;
    font-size: 14px;
    font-weight: 600;
    color: #1a1a1a;
    line-height: 44px;
    div {
      flex: 1;
      flex-grow: 3;
      text-align: center;
    }
    .material,
    .revoke {
      flex-grow: 1;
    }
  }
  // 内容
  .panel {
    display: flex;
    padding: 14px 0;
    border-bottom: 1px solid #dfe0eb;
    > div {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      flex: 1;
      flex-grow: 3;
    }
    /*最近10回合记录*/
    .last-ten-rounds {
      text-align: left;
      .steps-item {
        display: inline-block;
        width: 40px;
        text-align: center;
        padding: 0 8px;
        border-right: 1px solid #dfe0ea;
      }
    }

    /*训练素材*/
    .material-name {
      flex-grow: 1;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    // 撤销
    .revoke {
      flex-grow: 1;
      display: flex;
      justify-content: center;
      .trained-round {
        color: #3a5ff8;
        font-weight: 600;
      }
      .revoke-allow {
        color: #f12a2c;
      }
    }
    /*数据记录*/
    .data-record {
      display: flex;
      justify-content: center;
      .method-button {
        width: 80px;
        height: 32px;
        border-radius: 2px;
        border: 1px solid #e1e8ff;
        color: #1a1a1a;
        margin-right: 8px;
      }

      .method-button-flash {
        background: #3a5ff8;
        color: #fff;
        border-radius: 2px;
      }

      img {
        width: 32px;
        height: 32px;
        cursor: pointer;
        margin: 0 5px;
      }
    }
  }
}
</style>
