<!--固定时长计次-->
<template>
  <div class="countdown">
    <div class="top">
      <a-tag color="#E1E8FF" :title="data.name" style="color: #333; border-radius: 2px">
        {{ data.name }}
      </a-tag>
      <a-badge class="top-r" :count="historyRound" title="本堂课该行为记录次数" />
    </div>
    <a-row class="center">
      <div class="center-l" title="固定时长计次">
        <img src="~@/assets/icons/signal.png" alt="" />
        <img src="~@/assets/icons/hourglass.png" alt="" />
      </div>
      <div class="center-r">
        <a-col :span="24" style="display: flex; justify-content: space-around">
          <a-button type="primary" shape="circle" @click="handleDel" :disabled="count == 0 || addSubBtn">-</a-button>
          <span>{{ count }}</span>
          <a-button type="primary" shape="circle" @click="handleAdd" :disabled="totalSeconds < 1 || addSubBtn"
            >+</a-button
          >
        </a-col>

        <a-col
          :span="24"
          v-if="!isSetMinuteSecond"
          style="display: flex; justify-content: space-around; align-items: center"
        >
          <a-input
            size="small"
            type="text"
            :min="0"
            :max="100000"
            v-model.trim="minute"
            :default-value="minute"
            @focus="minute = null"
            style="width: 45%"
          />
          :
          <a-input
            size="small"
            type="text"
            :min="0"
            :max="59"
            v-model.trim="second"
            :default-value="second"
            @focus="second = null"
            style="width: 45%"
          />
          <a-icon type="check-circle" theme="twoTone" two-tone-color="#3a5ff8" @click="handleSaveTime" title="保存" />
        </a-col>
        <a-col :span="24" v-if="isSetMinuteSecond">{{ showTime }}</a-col>
      </div>
    </a-row>
    <a-row class="bottom">
      <a-col :span="12" v-if="isSetMinuteSecond">
        <a-tag color="#3a5ff8" @click="handleSrart" v-if="btnFlag">开始</a-tag>
        <a-tag color="#F8A70E" @click="handleFinish" v-else>结束</a-tag>
      </a-col>
    </a-row>
  </div>
</template>
<script>
import { cloneDeep } from 'lodash'
export default {
  props: {
    tempList: {
      type: Array,
      default: () => {
        return []
      }
    },
    data: {
      type: Object,
      default: () => {
        return {}
      }
    },
    index: {
      type: Number,
      default: -1
    }
  },
  data() {
    return {
      count: 0, // 次数
      minute: 0, // 分
      second: 0, // 秒
      isSetMinuteSecond: false, //  是否设置分,秒
      btnFlag: true, // 开始、结束按钮
      addSubBtn: true, // 加、减按钮
      totalSeconds: 0, // 总秒数
      setTotalSeconds: 0, // 暂存设置的总秒数,每次重新开始用这个数
      timer: null // 定时器
    }
  },
  created() {
    this.totalSeconds = this.data.totalSeconds || 0
    this.setTotalSeconds = this.data.setTotalSeconds || 0
    this.isSetMinuteSecond = this.data.isSetMinuteSecond
  },
  computed: {
    // 格式化显示描述
    showTime() {
      let _minute = '00'
      let _second = '00'
      if (this.totalSeconds < 60) {
        _second = this.formatTime(this.totalSeconds)
      } else {
        _minute = this.formatTime(parseInt(this.totalSeconds / 60))
        _second = this.formatTime(parseInt(this.totalSeconds % 60))
      }
      return `${_minute} : ${_second}`
    },
    historyRound() {
      const { tempList, data } = this
      return tempList
        ? tempList.filter(item => {
            return item.behaviorRecordId === data.id
          }).length
        : 0
    }
  },

  methods: {
    // 保存秒数
    handleSaveTime() {
      if (!this.minute && !this.second) {
        this.$notification['error']({ message: '请设置倒计时分、秒' })
        return
      }
      this.totalSeconds = Number(this.minute) * 60 + Number(this.second)
      this.setTotalSeconds = this.totalSeconds
      this.isSetMinuteSecond = !this.isSetMinuteSecond
      this.$emit(
        'getSumTime',
        Object.assign({}, this.data, {
          totalSeconds: this.totalSeconds,
          isSetMinuteSecond: this.isSetMinuteSecond,
          setTotalSeconds: this.setTotalSeconds
        })
      )
    },
    // 开始按钮
    handleSrart() {
      this.btnFlag = !this.btnFlag
      this.addSubBtn = !this.addSubBtn
      this.timer = setInterval(() => {
        this.totalSeconds--
        if (this.totalSeconds < 1) {
          this.addSubBtn = !this.addSubBtn
          this.handleFinish()
        }
      }, 1000)
    },
    // 结束按钮
    handleFinish() {
      clearInterval(this.timer)
      const duration = Number(this.data.totalSeconds) - Number(this.totalSeconds)
      const frequency = this.count
      this.$emit('finish', { item: cloneDeep(this.data), index: this.index, duration, frequency })
      this.$nextTick(() => {
        this.initData()
      })
    },
    // 格式化数字 往前补0
    formatTime(number) {
      return Number(number) < 10 ? `0${number}` : number
    },
    // 加
    handleAdd() {
      this.count++
    },

    // 减
    handleDel() {
      this.count > 0 && this.count--
    },

    // 初始化数据
    initData() {
      this.btnFlag = true
      this.addSubBtn = true
      this.count = 0
      this.totalSeconds = this.setTotalSeconds
    }
  }
}
</script>
<style scoped lang="less">
.countdown {
  height: 100%;
  position: relative;
  .top {
    position: relative;
    display: flex;
    justify-content: flex-start;
    padding-bottom: 5px;
    .top-l {
      overflow: hidden;
    }
    .top-r {
      position: absolute;
      top: -15px;
      right: -13px;
    }
  }
  .center {
    display: flex;
    .center-l {
      img {
        display: block;
        width: 20px;
        height: 20px;
        margin-right: 5px;
      }
    }
    .center-r {
      flex: 1;
    }
    /deep/ .ant-col {
      text-align: center;
    }
    /deep/ .ant-btn {
      width: 16px;
      height: 16px;
      min-width: 0;
      line-height: 16px;
      overflow: hidden;
      > span {
        position: relative;
        top: -2px;
      }
    }
  }
  .bottom {
    position: absolute;
    bottom: 0;
    right: 0;
  }
}
</style>
