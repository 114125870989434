<!--模板3 固定时长次数（固定时长计次）-->
<template>
  <div class="fixed-duration">
    <div class="fixed-duration-top">
      <!--狗子-->
      <dog :width="80" :height="69" :dog-percentage="DogPercentage" />
      <div class="time-panel">
        <div>
          <img src="~@/assets/icons/hourglass.png" alt="" />
          <span>{{ showTime[0] }}:{{ showTime[1] }}</span>
          <span class="tail">s</span>
        </div>
        <div class="time-panel-target">
          目标频率：{{ formatSecondsToHourMinuteSecond(totalSeconds) }}{{ data.minimumRounds }}次
        </div>
      </div>
      <div class="btn-panel">
        <div class="btn-left">
          <a-button block type="primary" shape="round" @click="handleStart" v-if="buttonType == 1">开始计时</a-button>
          <a-button
            block
            type="primary"
            shape="round"
            @click="handleEnd"
            style="background-color: #f8a70e; border-color: #f8a70e"
            v-if="buttonType == 2"
            >结束计时</a-button
          >
          <a-button block type="primary" shape="round" @click="handleRestart" v-if="buttonType == 3">重新开始</a-button>
        </div>
        <div class="btn-right">
          <a-button
            :class="[buttonType != 3 ? '' : 'btn-green']"
            block
            type="primary"
            shape="round"
            @click="handleNextRound"
            :disabled="buttonType != 3"
            >保存记录</a-button
          >
        </div>
      </div>
    </div>
    <!--table-->
    <div class="fixed-duration-bottom">
      <div class="head">
        <div>最近{{ lastRounds }}回合记录</div>
        <div class="material">训练素材</div>
        <div class="round"></div>
        <div class="record">数据记录</div>
      </div>
      <div v-for="(item, index) in data.materialList" :key="'item' + index" class="panel">
        <!--最近10回合记录-->
        <div class="last-ten-rounds">
          <span
            v-for="(item1, index1) in item.resultList.slice(-lastRounds)"
            :key="'item1' + String(index1)"
            class="steps-item"
          >
            {{ item1 }}
          </span>
          <span v-for="index1 in calcEmptyRecord(item)" :key="'empty' + String(index1)" class="steps-item">-</span>
        </div>
        <!--素材-->
        <div class="material-name">
          <div class="material">{{ item.name }}</div>
        </div>
        <div class="round">
          <span style="color: rgb(58, 95, 248)">{{ data.materialList[index].timeList.length }}</span
          >/{{ data.minimumRounds }}
        </div>
        <!--数据统计-->
        <div class="data-record">
          <!--减-->
          <a-icon
            type="minus-circle"
            theme="twoTone"
            two-tone-color="#F12A2C"
            :style="{ fontSize: '32px' }"
            v-if="buttonType == 2 && data.materialList[index].count > 0"
            @click="handleSub({ index })"
          />
          <a-icon v-else type="minus-circle" :style="{ fontSize: '32px', color: '#A6A7AE' }" />
          <span class="count">{{ data.materialList[index].count || '未计次' }}</span>
          <!--加-->
          <a-icon
            type="plus-circle"
            theme="twoTone"
            two-tone-color="#52BE7F"
            :style="{ fontSize: '32px' }"
            @click="handleAdd({ index })"
            v-if="buttonType == 2"
          />
          <a-icon v-else type="plus-circle" :style="{ fontSize: '32px', color: '#A6A7AE' }" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { cloneDeep } from 'lodash'
import { mapActions } from 'vuex'
import dog from '../dog/dog'
import { formatSecondsToHourMinuteSecond, formatSecondsToMinuteSecond } from '@/utils/util'

export default {
  components: {
    dog
  },
  props: {
    current: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      seconds: 0, // 倒计时秒数
      totalSeconds: 0, // 狗子 总秒数(目标秒数)
      lastRounds: 10, // 最近回合数
      buttonType: 1, // 按钮类型
      data: {}
    }
  },
  created() {
    this.data = cloneDeep(this.current)
    const { childData } = cloneDeep(this.current)
    if (childData) {
      const { buttonType, seconds, totalSeconds = 0 } = childData
      this.buttonType = buttonType
      this.seconds = seconds
      this.totalSeconds = totalSeconds
      buttonType === 2 && this.handleStart()
      delete this.data.childData
    } else {
      this.seconds = this.data.seconds
      this.totalSeconds = this.data.seconds
    }
  },
  computed: {
    // 狗子百分比
    DogPercentage() {
      const percentage = ((this.totalSeconds - this.seconds) / this.totalSeconds) * 100
      return percentage < 100 ? percentage : 100
    },
    // 秒转为 分+秒
    showTime() {
      const { seconds } = this
      return formatSecondsToMinuteSecond(seconds)
    }
  },
  methods: {
    formatSecondsToHourMinuteSecond,
    ...mapActions(['changeSpinning']),
    // 开始
    handleStart() {
      if (this.seconds < 1) return
      this.buttonType = 2
      this.timer = setInterval(() => {
        this.seconds--
        if (this.seconds < 1) {
          this.buttonType = 3
          clearInterval(this.timer)
        }
      }, 1000)
    },
    // 结束
    handleEnd() {
      clearInterval(this.timer)
      this.buttonType = 3
    },
    // 重新开始
    handleRestart() {
      this.data.materialList.map((item, index) => {
        const { count } = item
        if (count > 0) {
          item.count = 0
        }
        return item
      })
      this.seconds = this.totalSeconds
      this.handleStart()
    },
    // 加
    handleAdd({ index }) {
      if (this.buttonType !== 2) return
      this.data.materialList[index].count++
    },
    // 减
    handleSub({ index }) {
      if (this.buttonType !== 2) return
      const { count } = this.data.materialList[index]
      count > 0 && this.data.materialList[index].count--
    },

    // 下一回合
    async handleNextRound() {
      const { totalSeconds, seconds } = this
      this.data.materialList.map((item, index) => {
        const { count } = item
        if (count > 0) {
          item.frequencyList.push(count)
          item.timeList.push(Number(totalSeconds) - Number(seconds))
          item.resultList.push(Number(count))
          item.count = 0
        }
        return item
      })
      await this.saveClassTraining()
      this.buttonType = 1
      this.seconds = totalSeconds
    },
    // 保存训练时间
    saveClassTraining() {
      const { phaseId: planSkillPhaseId, materialList } = this.data
      // frequency 第3种情况 才传
      // 临时变量
      const classTrainingList = []
      // 遍历素材列表
      for (const item of materialList) {
        const { name: material, id: materialId, timeList = [], frequencyList = [] } = item
        timeList &&
          timeList.forEach((item1, index1) => {
            const tempClassTraining = {
              materialId,
              material,
              round: index1 + 1,
              duration: item1,
              trainingDuration: item1,
              frequency: frequencyList[index1]
            }
            classTrainingList.push(tempClassTraining)
          })
      }
      if (!classTrainingList.length) return

      const params = {
        classTrainingList,
        planSkillPhaseId
      }
      this.$emit('saveClassData', params)
    },
    // 返回数据
    getData({ isClearTime = true }) {
      if (isClearTime) {
        clearInterval(this.timer)
      }
      const childData = {
        active: this.active,
        buttonType: this.buttonType,
        seconds: this.seconds,
        totalSeconds: this.totalSeconds,
        count: this.count,
        percentage:
          this.buttonType === 2 ? ((this.seconds - 1) / this.totalSeconds) * 100 : this.buttonType === 3 ? 0 : 100,
        timeType: 'sub'
      }
      return Object.assign({}, this.data, { childData })
    },
    // 计算空记录
    calcEmptyRecord(item) {
      const { resultList } = item
      const { lastRounds } = this
      return lastRounds - resultList.slice(-lastRounds).length
    }
  },
  beforeDestroy() {
    clearInterval(this.timer)
  }
}
</script>
<style lang="less" scoped>
.fixed-duration {
  display: flex;
  flex-direction: column;
  /*顶部*/
  .fixed-duration-top {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 18px;
    margin-bottom: 8px;
    /*时间*/
    .time-panel {
      text-align: center;
      margin-left: 26px;
      margin-right: 24px;
      font-size: 32px;
      font-weight: 600;
      color: #1a1a1a;
      img {
        width: 29px;
        height: 30px;
        vertical-align: middle;
        margin-right: 10px;
        position: relative;
        top: -3px;
      }
      .time-panel-target {
        font-size: 14px;
        color: #1a1a1a;
      }
    }
    /*按钮*/
    .btn-panel {
      display: flex;
      .btn-left {
        width: 160px;
        margin-right: 24px;
      }
      .btn-right {
        width: 160px;
        .btn-green {
          background-color: #52be7f;
          color: #fff;
          border: 1px solid #52be7f;
        }
      }
    }
  }
  /*底部*/
  .fixed-duration-bottom {
    .head {
      display: flex;
      height: 44px;
      background: #f7f8fc;
      font-size: 14px;
      font-weight: 600;
      color: #1a1a1a;
      line-height: 44px;
      padding: 0 24px;
      div {
        flex: 1;
        flex-grow: 3;
        text-align: center;
      }
      .material,
      .round {
        flex-grow: 1;
      }
    }
    /*每一行素材*/
    .panel {
      display: flex;
      padding: 14px 0;
      border-bottom: 1px solid #dfe0eb;
      > div {
        flex: 1;
        flex-grow: 3;
        flex-wrap: wrap;
        display: flex;
        align-items: center;
      }
      /*最近10回合记录*/
      .last-ten-rounds {
        text-align: left;
        .steps-item {
          display: inline-block;
          width: 40px;
          text-align: center;
          padding: 0 8px;
          border-right: 1px solid #dfe0ea;
        }
      }

      /*训练素材*/
      .material-name {
        flex-grow: 1;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .round {
        flex-grow: 1;
        display: flex;
        justify-content: center;
      }

      /*数据记录*/
      .data-record {
        display: flex;
        justify-content: center;
        .count {
          display: inline-block;
          text-align: center;
          min-width: 96px;
          padding: 0 24px;
          font-size: 16px;
          font-weight: 600;
          color: #1a1a1a;
        }
      }
    }
  }
}
</style>
