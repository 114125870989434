<!--计时-->
<template>
  <div class="timing">
    <div class="top">
      <a-tag color="#E1E8FF" :title="data.name" style="color: #333; border-radius: 2px">
        {{ data.name }}
      </a-tag>
      <a-badge class="top-r" :count="historyRound" title="本堂课该行为记录次数" />
    </div>
    <a-row class="center" type="flex" justify="space-around" align="middle">
      <a-col :span="12">
        <img src="~@/assets/icons/clock1.png" alt="" title="计时" />
      </a-col>
      <a-col :span="12">
        {{ showTime }}
      </a-col>
    </a-row>
    <a-row class="bottom" type="flex" justify="end">
      <a-tag color="#3a5ff8" @click="handleSrart" v-if="btnFlag">开始</a-tag>
      <a-tag color="#F8A70E" @click="handleFinish" v-else>结束</a-tag>
    </a-row>
  </div>
</template>
<script>
import { cloneDeep } from 'lodash'
export default {
  props: {
    tempList: {
      type: Array,
      default: () => {
        return []
      }
    },
    data: {
      type: Object,
      default: () => {
        return {}
      }
    },
    index: {
      type: Number,
      default: -1
    }
  },
  data() {
    return {
      btnFlag: true, // 按钮显示
      totalSeconds: 0,
      timer: null
    }
  },
  computed: {
    showTime() {
      let minute = '00'
      let second = '00'
      if (this.totalSeconds < 60) {
        second = this.formatTime(this.totalSeconds)
      } else {
        minute = this.formatTime(parseInt(this.totalSeconds / 60))
        second = this.formatTime(parseInt(this.totalSeconds % 60))
      }
      return `${minute} : ${second}`
    },
    historyRound() {
      const { tempList, data } = this
      return tempList
        ? tempList.filter(item => {
            return item.behaviorRecordId === data.id
          }).length
        : 0
    }
  },

  methods: {
    // 开始按钮
    handleSrart() {
      this.btnFlag = !this.btnFlag
      this.timer = setInterval(() => {
        this.totalSeconds++
      }, 1000)
    },
    // 结束按钮
    handleFinish() {
      clearInterval(this.timer)
      this.btnFlag = !this.btnFlag
      this.$emit('finish', {
        item: cloneDeep(this.data),
        index: this.index,
        duration: this.totalSeconds,
        frequency: null
      })
      this.totalSeconds = 0
    },
    // 格式化数字 往前补0
    formatTime(number) {
      return number < 10 ? `0${number}` : number
    }
  }
}
</script>
<style scoped lang="less">
.timing {
  position: relative;
  height: 100%;
  .top {
    position: relative;
    display: flex;
    justify-content: flex-start;
    padding-bottom: 5px;
    .top-l {
      overflow: hidden;
    }
    .top-r {
      position: absolute;
      top: -15px;
      right: -13px;
    }
  }
  .center {
    img {
      width: 20px;
      height: 20px;
      margin-right: 5px;
    }
  }
  .bottom {
    position: absolute;
    bottom: 0;
    right: 0;
  }
}
</style>
