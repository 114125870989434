import request from '@/utils/request'

const studentApi = {
  list: '/student',
  stList: '/student/homeSchool',
  create: '/student',
  detail: studentId => `/student/${studentId}`,
  update: studentId => `/student/${studentId}`,
  // 维护学员家庭信息
  studentFamilyInfo: studentId => `/student/${studentId}`,
  bindPlanWriter: id => `/student/planWriter/${id}`,
  bindTeacher: id => `/student/teacher/${id}`,
  remove: phone => `/studentData/${phone}`,
  // 重新评估审核列表-列表
  evaluationReviewList: '/evaluations/reassessments',
  //  重新评估审核列表-修改状态
  evaluationReviewChangeStatus: recordId => `/evaluations/reassessments/status/${recordId}`,
  // 评估历史-列表(4次)
  getEvaluationHistoryList: studentId => `/student/${studentId}/evaluations/history`,
  // 根据设备id查询学员信息
  studentByMachineCode: machineCode => `/student/machineCode/${machineCode}`,
  //  是否可以绑定设备号
  isBindDevices: `/student/canBindMachineCode`,
  //  绑定设备号
  bindDevices: `/student/bindMachineCode`,
  checkPhone: '/student/check-cellphone',
  isUnfinishedClass: studentId => `/v3/teaching/is-has/unfinished-class/student/${studentId}`, // 是否有未结束课程

  beginNewClass: studentId => `/v3/teaching/delete/student/${studentId}`, // 重新开始(上课第一步阶段)

  continueHistoryClass: studentId => `/v3/teaching/is-reload/student/${studentId}` // 继续上课-重新载入
}
// 继续上课-重新载入
export function continueHistoryClass(parameter) {
  const { studentId } = parameter
  return request({
    url: studentApi.continueHistoryClass(studentId),
    method: 'get'
  })
}

// 重新开始(上课第一步阶段)
export function beginNewClass(parameter) {
  const { studentId } = parameter
  return request({
    url: studentApi.beginNewClass(studentId),
    method: 'delete'
  })
}

// 是否有未结束课程
export function isUnfinishedClass(parameter) {
  const { studentId } = parameter
  return request({
    url: studentApi.isUnfinishedClass(studentId),
    method: 'get'
  })
}

// 绑定设备号
export function saveBindDevices(parameter) {
  return request({
    url: studentApi.bindDevices,
    method: 'get',
    params: parameter
  })
}

//  是否可以绑定设备号
export function isBindDevices(parameter) {
  console.log(parameter)
  return request({
    url: studentApi.isBindDevices,
    method: 'get',
    params: parameter
  })
}

// 根据设备id查询学员信息
export function getStudentByMachineCode(parameter) {
  const { machineCode } = parameter
  return request({
    url: studentApi.studentByMachineCode(machineCode),
    method: 'get'
  })
}

// 编辑学员
export function studentUpdate(parameter) {
  const { id: studentId } = parameter
  delete parameter.id
  return request({
    url: studentApi.update(studentId),
    method: 'put',
    data: parameter
  })
}

// 学员详情
export function getStudentDetail(parameter) {
  const { studentId } = parameter
  return request({
    url: studentApi.detail(studentId),
    method: 'get'
  })
}

// 重新评估审核列表-列表
export function getEvaluationHistoryList(parameter) {
  const { studentId } = parameter
  return request({
    url: studentApi.getEvaluationHistoryList(studentId),
    method: 'get'
  })
}

// 重新评估审核-列表
export function evaluationReviewList(parameter) {
  return request({
    url: studentApi.evaluationReviewList,
    method: 'get',
    params: parameter
  })
}

//  重新评估审核-修改状态
export function evaluationReviewChangeStatus(parameter) {
  const { recordId, status } = parameter
  return request({
    url: studentApi.evaluationReviewChangeStatus(recordId),
    method: 'patch',
    data: { status }
  })
}

export function list(parameter) {
  return request({
    url: studentApi.list,
    method: 'get',
    params: parameter
  })
}

export function stList(parameter) {
  return request({
    url: studentApi.stList,
    method: 'get',
    params: parameter
  })
}

export function create(parameter) {
  return request({
    url: studentApi.create,
    method: 'post',
    data: parameter
  })
}
// 维护学员家庭信息
export function studentFamilyInfo(parameter) {
  const { id } = parameter
  delete parameter.id
  return request({
    url: studentApi.studentFamilyInfo(id),
    method: 'patch',
    data: parameter
  })
}

export function bindPlanWriter(parameter) {
  const { studentId } = parameter
  delete parameter.studentId

  return request({
    url: studentApi.bindPlanWriter(studentId),
    method: 'patch',
    data: parameter
  })
}

export function bindTeacher(parameter) {
  const { studentId } = parameter
  delete parameter.studentId

  return request({
    url: studentApi.bindTeacher(studentId),
    method: 'patch',
    data: parameter
  })
}

export function remove(parameter) {
  const { phone } = parameter
  delete parameter.phone

  return request({
    url: studentApi.remove(phone),
    method: 'get',
    data: parameter
  })
}

// 检查手机号是否重复
export function checkPhone(parameter) {
  return request({
    url: studentApi.checkPhone,
    method: 'get',
    params: parameter
  })
}
