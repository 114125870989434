<!--计次-->
<template>
  <div class="count">
    <div class="top">
      <a-tag color="#E1E8FF" :title="data.name" style="color: #333; border-radius: 2px">
        {{ data.name }}
      </a-tag>
    </div>
    <a-row class="bottom" type="flex" justify="space-around" align="middle">
      <img src="~@/assets/icons/signal.png" alt="" title="计次" />
      <a-button type="primary" shape="circle" @click="handleDel" :disabled="data.count < 1">-</a-button>
      <a-col :span="6" style="text-align: center">{{ data.count }}</a-col>
      <a-button type="primary" shape="circle" @click="handleAdd">+</a-button>
    </a-row>
  </div>
</template>
<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => {
        return {}
      }
    },
    index: {
      type: Number,
      default: -1
    }
  },
  data() {
    return {
      // count: 0
    }
  },
  methods: {
    // 加
    handleAdd() {
      this.data.count++
      this.getData()
    },

    // 减
    handleDel() {
      this.data.count > 0 && this.data.count--
      this.getData()
    },
    // 返回数据
    getData() {
      const { id, recordingMethod, count } = this.data
      this.$emit('getChildData', { id, recordingMethod, duration: null, frequency: count })
    }
  }
}
</script>
<style scoped lang="less">
.count {
  height: 100%;
  .top {
    display: flex;
    justify-content: flex-start;
    padding-bottom: 5px;
  }
  .bottom {
    img {
      width: 20px;
      height: 20px;
      margin-right: 5px;
    }
    /deep/ .ant-col {
      text-align: center;
    }
    /deep/ .ant-btn {
      width: 16px;
      height: 16px;
      min-width: 0;
      line-height: 16px;
      overflow: hidden;
      > span {
        position: relative;
        top: -2px;
      }
    }
  }
}
</style>
