<!--模板2 时长(计时计次)-->
<template>
  <div>
    <div class="duration">
      <!--左侧-->
      <div class="duration-l">
        <div class="panel">
          <!--选中的素材-->
          <div class="material">
            <div class="count">{{ data.materialList[active].timeList.length }}/{{ data.minimumRounds }}</div>
            <div class="name">{{ data.materialList[active].name }}</div>
          </div>
          <!--最近10回合-->
          <div class="last-ten-rounds">
            <div>最近{{ lastRounds }}回合记录：</div>
            <div v-if="active > -1">
              <span v-for="(item, index) in data.materialList[active].timeList" :key="index + '1'" class="steps-item">
                <a-icon v-if="item.type === 'false'" type="close" :style="{ color: '#F12A2C', fontSize: '17px' }" />
                <a-icon v-else-if="item.type === 'true'" type="check" :style="{ color: '#52BE7F', fontSize: '17px' }" />
                <span v-else>{{ item.type }}</span>
              </span>
              <span
                v-for="index1 in calcEmptyRecord(data.materialList[active])"
                :key="String(index1) + '2'"
                class="steps-item"
                >-</span
              >
            </div>
          </div>
          <!--时间和按钮-->
          <div class="time-btns">
            <div class="time-btns-l">
              <div class="time">
                <img src="~@/assets/icons/clock1.png" alt="" />
                <div class="time-number">{{ showTime[0] }}:{{ showTime[1] }}</div>
              </div>
              <div class="target">目标时长：{{ formatSecondsToHourMinuteSecond(totalSeconds) }}</div>
            </div>

            <div class="time-btns-r">
              <a-button block type="primary" shape="round" @click="handleStart" v-if="buttonType == 1"
                >开始计时</a-button
              >
              <a-button
                block
                type="primary"
                shape="round"
                @click="handleEnd"
                v-if="buttonType == 2"
                style="background-color: #f8a70e; border-color: #f8a70e"
                >结束计时</a-button
              >
            </div>
          </div>
          <!--狗子-->
          <dog :dog-percentage="DogPercentage" />
        </div>
      </div>
      <!--右侧-->
      <div class="duration-r">
        <div
          v-for="(item, index) in data.materialList"
          :key="index"
          :class="index === active ? 'active' : ''"
          @click="handleChoose({ index })"
        >
          <p>{{ item.name }}</p>
          <p>{{ item.timeList.length }}/{{ data.minimumRounds }}</p>
        </div>
      </div>

      <!--  弹窗-->
      <a-modal
        class="modal"
        v-model="visible"
        width="500px"
        title="请选择本次使用的辅助方式"
        :body-style="{ paddingTop: '38px', paddingLeft: '24px', paddingRight: '24px', paddingBottom: '50px' }"
        :maskClosable="false"
        :closable="true"
        okText="保存记录"
        @ok="handleNextRound"
        @cancel="handleStart"
        :ok-button-props="{ props: { shape: 'round', disabled: mode === '' ? true : false } }"
        :cancel-button-props="{ style: { display: 'none' } }"
      >
        <div class="modal-content">
          <a-radio-group class="list" v-model="mode" :style="{ marginBottom: '8px' }">
            <a-radio-button :value="i.id" v-for="(i, k) in methodList" :key="k">
              {{ i.id === '-1' ? i.name : i.id }}
            </a-radio-button>
          </a-radio-group>
          <a-button type="link" style="color: #f12a2c" @click="handleRestart">
            <a-icon type="redo" />
            记录错误重新计时
          </a-button>
        </div>
      </a-modal>
    </div>
  </div>
</template>
<script>
import dog from '../dog/dog'
import { cloneDeep } from 'lodash'
import { mapActions } from 'vuex'
import { formatSecondsToHourMinuteSecond, formatSecondsToMinuteSecond } from '@/utils/util'

export default {
  components: {
    dog
  },
  props: {
    current: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      visible: false,
      mode: '',
      // 辅助方式列表
      methodList: [
        {
          id: '-1',
          name: '无辅助'
        }
      ],
      active: Number, // 选中索引
      timer: null, // 定时器
      buttonType: 1, // 按钮类型
      lastRounds: 10, // 最近回合数
      seconds: 0, // 计时秒数
      totalSeconds: 0, // 目标秒数
      data: {}
    }
  },
  created() {
    this.data = cloneDeep(this.current)
    const { childData, totalSeconds, trainingAssistanceModeList } = cloneDeep(this.current)
    this.methodList = [...this.methodList, ...trainingAssistanceModeList]
    this.totalSeconds = totalSeconds
    if (childData) {
      const { active, buttonType, seconds } = childData
      this.active = active
      this.buttonType = buttonType
      this.seconds = seconds
      buttonType === 2 && this.handleStart()
      delete this.data.childData
    } else {
      this.active = 0
    }
  },
  computed: {
    // 狗子百分比
    DogPercentage() {
      const percentage = (this.seconds / this.totalSeconds) * 100
      return percentage < 100 ? percentage : 100
    },
    // 秒转为 分+秒
    showTime() {
      return formatSecondsToMinuteSecond(this.seconds)
    }
  },
  methods: {
    formatSecondsToHourMinuteSecond,
    ...mapActions(['changeSpinning']),
    // 开始
    handleStart() {
      this.buttonType = 2
      this.timer = setInterval(() => {
        this.seconds++
      }, 1000)
    },
    // 结束
    handleEnd() {
      if (this.seconds > 0) {
        clearInterval(this.timer)
        // this.buttonType = 3
        this.visible = true
      }
    },
    // 重新开始
    handleRestart() {
      // this.buttonType = 1
      this.visible = false
      this.seconds = 0
      this.mode = ''
      this.handleStart()
    },
    // 下一回合(保存记录)
    handleNextRound() {
      const { totalSeconds, seconds, mode } = this
      if (mode === '') return
      const type = mode === '-1' ? seconds >= totalSeconds : mode
      this.data.materialList[this.active].timeList.push({ seconds, type: String(type) })

      // this.handleRestart()
      this.buttonType = 1
      this.seconds = 0
      this.visible = false
      this.mode = ''
      this.saveClassTraining()
    },

    // 选择右侧
    handleChoose({ index }) {
      if (Number(this.buttonType) === 2) {
        this.$notification['error']({ message: '请先结束正在训练的素材' })
        return
      }
      this.buttonType = 1 // 按钮类型
      this.seconds = 0 // 总秒数
      this.active = index
    },
    // 返回数据
    getData({ isClearTime = true }) {
      if (isClearTime) {
        clearInterval(this.timer)
      }
      const tempData = cloneDeep(this.data)
      const childData = {
        active: this.active,
        buttonType: this.buttonType,
        seconds: this.seconds,
        timeType: 'add'
      }
      if (this.buttonType === 2 || this.buttonType === 3) {
        tempData.childData = childData
      }
      return tempData
    },

    // 保存训练时间
    saveClassTraining() {
      const { phaseId: planSkillPhaseId, materialList } = this.data
      // frequency 第3种情况 才传
      // 临时变量
      const classTrainingList = []
      if (!materialList) return
      // 遍历素材列表
      for (const item of materialList) {
        const { name: material, id: materialId, timeList = [] } = item
        timeList &&
          timeList.forEach((item1, index1) => {
            const tempClassTraining = {
              materialId,
              material,
              round: index1 + 1,
              trainingDuration: item1.seconds,
              resultList: [item1.type]
            }
            classTrainingList.push(tempClassTraining)
          })
      }

      const params = {
        classTrainingList,
        planSkillPhaseId
      }
      this.$emit('saveClassData', params)
    },
    // 计算空记录
    calcEmptyRecord({ timeList }) {
      const { lastRounds } = this
      return lastRounds - timeList.slice(-lastRounds).length
    }
  },
  beforeDestroy() {
    clearInterval(this.timer)
    this.active = Number // 选中索引
    this.timer = null // 定时器
    this.buttonType = 1 // 按钮类型
    this.seconds = Number // 总秒数
  }
}
</script>
<style lang="less" scoped>
.duration {
  display: flex;
  .duration-l {
    display: flex;
    justify-content: center;
    padding-top: 18px;
    flex: 1;
    .panel {
      display: flex;
      flex-direction: column;
      align-items: center;
      > div {
        display: flex;
        justify-content: center;
        margin-bottom: 16px;
      }
      .material {
        width: 150px;
        min-height: 64px;
        background: #e1e8ff;
        display: flex;
        flex-direction: column;
        text-align: center;
        color: #1a1a1a;
        .name {
          font-size: 14px;
          color: #333333;
        }
        .count {
          font-size: 12px;
          color: #8c8c8c;
        }
      }

      /*最近10回合记录*/
      .last-ten-rounds {
        text-align: left;
        .steps-item {
          display: inline-block;
          width: 40px;
          text-align: center;
          padding: 0 8px;
          border-right: 1px solid #dfe0ea;
        }
      }
      /*时间和开始结束俺妞*/
      .time-btns {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .time-btns-l {
          .time {
            display: flex;
            align-items: center;
            img {
              width: 36px;
              height: 36px;
              margin-right: 12px;
            }
            .time-number {
              font-size: 36px;
              font-weight: 600;
              color: #333333;
            }
          }

          .target {
            font-size: 14px;
            color: #1a1a1a;
            text-align: center;
          }
        }

        .time-btns-r {
          width: 160px;
          margin-left: 24px;
          .btn-orange {
            background-color: #ff7a55;
            color: #fff;
            border: 1px solid #ff7a55;
          }
        }
      }
    }
  }
  .duration-r {
    width: 160px;
    overflow-y: auto;
    height: calc(100vh - 206px);
    padding-left: 10px;
    background: #f7f8fc;
    div {
      width: 150px;
      height: 64px;
      background: #ffffff;
      border: 1px solid #dfe0ea;
      border-radius: 4px;
      font-size: 14px;
      color: #595959;
      padding-top: 10px;
      margin-bottom: 8px;
      cursor: pointer;
      float: right;
      margin-right: 5px;
      p {
        text-align: center;
        font-size: 14px;
        color: #595959;
        margin-bottom: 0;
      }
    }
    .active {
      background: #e1e8ff;
      border-radius: 4px;
      border: 1px solid #e1e8ff;
    }
  }
}
/*弹窗*/
.modal {
  .modal-content {
    display: flex;
    flex-direction: column;
    .list {
      display: flex;
      justify-content: space-around;
      margin-bottom: 24px;
      /deep/ .ant-radio-button-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 96px;
        height: 38px;
        border-left: 1px solid #e1e8ff;
        color: #1a1a1a;
      }

      /deep/ .ant-radio-button-wrapper-checked {
        background: #3a5ff8;
        border: 1px solid #dfe0eb;
        color: #fff;
      }
    }
  }
}
/*定义滚动条高宽及背景
高宽分别对应横竖滚动条的尺寸*/
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  background-color: rgba(0, 0, 0, 0.08);
}
/*定义滚动条轨道
 内阴影+圆角*/
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  background-color: rgba(0, 0, 0, 0);
}
/*定义滑块
 内阴影+圆角*/
::-webkit-scrollbar-thumb {
  border-radius: 16px;
  -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0.08);
  background-color: rgba(144, 147, 153, 0.3);
}
</style>
