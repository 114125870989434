<!--上课的狗哥-->
<template>
  <div class="dog-wrap">
    <div class="dog" :style="{ width: width + 'px', height: height + 'px' }" :class="!visible ? 'hide' : ''">
      <img class="colour" src="~@/assets/dog@2x.png" alt="" />
      <div class="grey" :style="{ height: `${100 - dogPercentage}%` }">
        <img src="~@/assets/dog-grey@2x.png" alt="" />
      </div>
    </div>
    <a-button type="link" @click="visible = !visible">{{ visible ? '隐藏' : '显示' }}</a-button>
  </div>
</template>

<script>
export default {
  name: 'Dog',
  props: {
    dogPercentage: {
      type: Number,
      default: 0
    },
    width: {
      type: Number,
      default: 161
    },
    height: {
      type: Number,
      default: 139
    }
  },
  data() {
    return {
      visible: true
    }
  }
}
</script>

<style lang="less" scoped>
.dog-wrap {
  display: flex;
  flex-direction: column;
  .dog {
    border: 1px solid #e1e8ff;
    position: relative;
    .grey {
      position: absolute;
      top: 0;
      left: 0;
      overflow: hidden;
      height: 100%;
      img {
        width: 100%;
      }

      img,
      div {
        transition-timing-function: linear;
        transition-duration: 0.3s;
      }
    }
    > img {
      width: 100%;
      height: 100%;
    }
  }

  .hide {
    img,
    div {
      opacity: 0;
    }
  }
}
</style>
