import * as Api from '@/api/serviceManager'
import moment from 'moment'
export default {
  data() {
    return {
      visibleError: false,
      onLine: navigator.onLine,
      temporaryStorageTimer: null, // 自动暂存计时器
      // 实时保存的状态
      noticeIcon: {
        pending: require('@/assets/report/report/ico_pending.png'),
        success: require('@/assets/report/report/ico_success.png'),
        error: require('@/assets/report/report/ico_error.png')
      },
      noticeText: {
        pending: '正在保存中',
        success: '',
        error: '未检测到网络，保存失败'
      },
      reportStatus: '', // 暂存状态
      saveType: '', // 保存类型  auto 自动  manual 手动
      autoSaveSconds: 60 // 自动保存时间（秒）
    }
  },
  mounted() {
    window.addEventListener('online', this.updateOnlineStatus)
    window.addEventListener('offline', this.updateOfflineStatus)
  },
  methods: {
    // 自动暂存
    beginAutoSave() {
      this.saveType = 'auto'
      this.temporaryStorageTimer = setTimeout(() => {
        this.reportStatus = ''
        this.autoSave()
      }, this.autoSaveSconds * 1000)
    },
    // 手动暂存
    async handleTemporaryStorageData() {
      this.saveType = 'manual'
      await this.closeAutoSave()
      this.autoSave()
    },
    // 暂存数据
    autoSave() {
      this.closeAutoSave()
      this.save().then(() => {
        this.beginAutoSave()
      }).catch(() => {
        this.closeAutoSave()
      })
    },
    async save() {
      // if (this.current.recordingMethod === 'STEP_SUCCESS_RATE') {
      await this.getActiveSkillPhaseChildData()
      // }

      const {
        $refs,
        query: {
          teachingClassId
        },
        classTrainingListDTOList,
        teachingSkillList,
        isBehaviorRecord,
        activeLeft,
        $message,
      } = this

      // 需要保存的数据放到这里
      let temporaryData = {
        teachingSkillList,
        classTrainingListDTOList,
        activeLeft, // 左侧选中
        isBehaviorRecord, // 是否需要行为记录
        behaviorRecordList: [], // 行为记录-列表
        classBehaviorRecordList: [] // 行为记录-上课数据
      }

      // 需要行为记录
      if (isBehaviorRecord) {
        await $refs.recording.saveClassTraining().then(res => {
          const {
            behaviorRecordList,
            classBehaviorRecordList
          } = res
          // 行为记录数据
          temporaryData = {
            ...temporaryData,
            ...{
              behaviorRecordList,
              classBehaviorRecordList
            }
          }
        })
      }
      // console.log(temporaryData)
      // 参数固定,不能动
      const params = {
        teachingClassId: Number(teachingClassId),
        temporaryData: JSON.stringify(temporaryData)
      }
      return new Promise((resolve, reject) => {
        this.reportStatus = 'pending'
        this.noticeText.success = ''
        Api.classBeginRealTimeSave(params)
          .then(res => {
            const {
              code,
            } = res
            if (code === 0) {
              this.noticeText.success = `${moment(new Date()).format('HH:mm:ss')}保存成功`
              this.reportStatus = 'success'
              // 手动保存出现提示
              if (this.saveType === 'manual') {
                $message.success('保存成功')
              }
              resolve()
            } else {
              this.reportStatus = 'error'
              reject()
            }
          })
          .catch((err) => {
            console.log(err)
            this.reportStatus = 'error'
            // this.error()
            this.visibleError = true
            reject()
          })
      })
    },
    // 结束课程
    async finishTeaching() {
      const {
        query: {
          teachingClassId
        },
        isBehaviorRecord,
        classTrainingListDTOList,
        $refs,
      } = this

      // 参数固定,不能动
      let params = {
        classTrainingListDTOList, // 技能阶段-上课数据
        teachingClassId: Number(teachingClassId),
        classBehaviorRecordList: [],
      }

      // 需要行为记录
      if (isBehaviorRecord) {
        await $refs.recording.saveClassTraining().then(res => {
          const {
            classBehaviorRecordList
          } = res
          params = {
            ...params,
            ...{
              classBehaviorRecordList
            }
          }
        })
      }

      return new Promise((resolve, reject) => {
        Api.finishTeaching(params)
          .then(res => {
            const {
              code,
              msg
            } = res
            if (code === 0) {
              resolve()
            } else {
              this.$notification['error']({
                message: msg
              })
              reject()
            }
          })
          .catch(() => {
            reject()
          })
      })
    },
    handleOk() {
      this.reportStatus = ''
      this.iconLoading = false
      this.closeAutoSave()
      this.visibleError = false
    },

    //  关闭自动暂存
    closeAutoSave() {
      clearInterval(this.temporaryStorageTimer)
      this.temporaryStorageTimer = null
    },
    // 网络连接成功
    updateOnlineStatus() {
      this.onLine = true
      this.beginAutoSave()
      this.$message.destroy()
    },
    // 网络断开连接
    updateOfflineStatus() {
      this.onLine = false
      this.reportStatus = ''
      this.iconLoading = false
      this.closeAutoSave()
      // console.log(new Date())
      this.$message.error({
        content: '未检测到网络，保存失败',
        duration: 0
      })
    },
    removeOnLineOffLine() {
      // console.log('removeEventListener')
      window.removeEventListener('online', this.closeAutoSave())
      window.removeEventListener('offline', this.closeAutoSave())
    }
  }
}