<!-- 家校训练服务-上课-开始上课 -->
<template>
  <div class="classes-begin">
    <a-row :gutter="24" style="overflow: hidden; margin-left: 0">
      <a-col :lg="3" :md="4" :sm="5" :xs="5" style="padding-left: 0; padding-right: 10px">
        <!--左侧-->
        <ul class="left-panel" :class="[recordStatus && isBehaviorRecord ? 'left-panel' : 'left-panel-long']">
          <template v-for="(item, index) in teachingSkillList">
            <!-- 模板1 普通-->
            <li
              class="skill"
              :class="activeLeft == index ? 'active' : ''"
              :key="index"
              v-if="item.recordingMethod == 'SUCCESS_RATE' || item.recordingMethod == 'STEP_SUCCESS_RATE'"
              @click="handleChooseLeft({ item, index })"
            >
              <!-- 技能名字 -->
              <div class="skill-head" :title="item.skillName">{{ item.skillName }}</div>
              <div class="skill-content">
                <!-- 阶段、回合 -->
                <div class="skill-content-top">
                  <div class="skill-content-top-c">第{{ convertToChineseNumber(item.phaseNumber) }}阶段</div>
                  <div class="skill-content-top-r">{{ item.minimumRounds }}回合</div>
                </div>
                <!-- 阶段名称 -->
                <div class="skill-content-bottom" :title="item.skillPhaseName">
                  {{ item.skillPhaseName }}
                </div>
              </div>
            </li>
            <!--模板2 时长(计时)-->
            <li
              class="skill clearfix"
              :class="activeLeft == index ? 'active' : ''"
              :key="index"
              v-if="item.recordingMethod == 'DURATION'"
              @click="handleChooseLeft({ item, index })"
            >
              <!-- 技能名字 -->
              <div class="skill-head" :title="item.skillName">{{ item.skillName }}</div>
              <div class="skill-content">
                <!-- 时间、阶段、回合 -->
                <div class="skill-content-top">
                  <div class="skill-content-top-l clock clock-green" v-if="item.childData && activeLeft != index">
                    <img src="~@/assets/icons/clock-green.png" alt="" />
                    <span class="count">{{ showTime(item.childData) }}</span>
                  </div>
                  <div class="skill-content-top-c">第{{ convertToChineseNumber(item.phaseNumber) }}阶段</div>
                  <div class="skill-content-top-r" v-if="!item.childData || activeLeft == index">
                    {{ item.minimumRounds }}回合
                  </div>
                </div>
                <!-- 阶段名称 -->
                <div class="skill-content-bottom" :title="item.skillPhaseName">
                  {{ item.skillPhaseName }}
                </div>
              </div>
            </li>
            <!--模板3 固定时长次数(倒计时)-->
            <li
              class="skill clearfix"
              :class="activeLeft == index ? 'active' : ''"
              :key="index"
              v-if="item.recordingMethod == 'NUMBER_SUCCESS'"
              @click="handleChooseLeft({ item, index })"
            >
              <!-- 技能名字 -->
              <div class="skill-head" :title="item.skillName">{{ item.skillName }}</div>
              <!-- 倒计时黄色背景 -->
              <div
                class="progress"
                :style="{ width: item.childData && item.childData.percentage + '%' }"
                v-show="item.childData && item.childData.buttonType !== 1 && activeLeft != index"
              ></div>
              <div class="skill-content">
                <!-- 时间、阶段、回合 -->
                <div class="skill-content-top">
                  <div
                    class="skill-content-top-l clock clock-black"
                    v-if="item.childData && item.childData.buttonType !== 1 && activeLeft != index"
                  >
                    <img src="~@/assets/icons/clock-black.png" alt="" />
                    <span class="count">{{ showTime(item.childData) }}</span>
                  </div>
                  <div class="skill-content-top-c">第{{ convertToChineseNumber(item.phaseNumber) }}阶段</div>
                  <div
                    class="skill-content-top-r"
                    v-if="!item.childData || item.childData.buttonType == 1 || activeLeft == index"
                  >
                    {{ item.minimumRounds }}回合
                  </div>
                </div>
                <!-- 阶段名称 -->
                <div class="skill-content-bottom" :title="item.skillPhaseName">
                  {{ item.skillPhaseName }}
                </div>
              </div>
            </li>
          </template>
        </ul>
      </a-col>
      <a-col :lg="21" :md="20" :sm="19" :xs="19" style="padding-left: 0; padding-right: 0px">
        <!--右侧-->
        <div class="right-panel" :class="[recordStatus && isBehaviorRecord ? 'right-panel' : 'right-panel-long']">
          <!--顶部-->
          <div class="title">
            <div class="title-l">
              <div class="title-l-l">
                <img
                  v-if="query.sex === 'MALE'"
                  src="~@/assets/icons/male@2x.png"
                  style="width: 40px; margin-right: 8px"
                  alt=""
                />
                <img
                  v-if="query.sex === 'FEMALE'"
                  src="~@/assets/icons/female@2x.png"
                  style="width: 40px; margin-right: 8px"
                  alt=""
                />
                <div class="title-l-l-r">
                  <div style="line-height: 32px"><span class="label">上课学员：</span>{{ query.username }}</div>
                  <div><span class="label">授课老师：</span>{{ userInfo.username }}</div>
                </div>
              </div>
              <a-divider type="vertical" style="height: 100%; margin: 0 10px" />
            </div>
            <div class="title-r">
              <div class="title-r-t">
                <div class="title-r-t-phase">
                  <span class="label">阶段名称：</span>
                  <span class="name">
                    <a-tooltip trigger="click">
                      <template slot="title"> {{ current.skillPhaseName }} </template>
                      {{ current.skillPhaseName }}
                    </a-tooltip>
                  </span>
                  <span class="more" @click="handleDetail()">查看更多</span>
                </div>
                <div class="title-r-t-btn">
                  <a-button
                    type="primary"
                    shape="round"
                    :disabled="reportStatus === 'pending'"
                    @click="handleTemporaryStorageData"
                    style="margin-right: 8px"
                    :class="reportStatus === 'pending' ? '' : 'temp-btn'"
                    >暂存课程数据</a-button
                  >
                  <a-button
                    type="primary"
                    shape="round"
                    icon="poweroff"
                    :loading="iconLoading"
                    :disabled="reportStatus === 'pending' || iconLoading"
                    @click="handleGoResult()"
                    >{{ iconLoading ? '请稍后……' : '结束课程' }}</a-button
                  >
                </div>
              </div>
              <div class="title-r-b">
                <span class="label">辅助方式：</span>
                <span
                  class="assist-method-item"
                  v-if="current.trainingAssistanceModeList && current.trainingAssistanceModeList.length > 0"
                >
                  <span
                    v-for="(trainingAssistanceMode, trainingAssistanceModeIndex) in current.trainingAssistanceModeList"
                    :key="trainingAssistanceModeIndex"
                  >
                    <span class="number">{{ trainingAssistanceMode.id }}</span>
                    <span class="number-name">{{ trainingAssistanceMode.name }}</span>
                  </span>
                </span>
                <span v-else>您已选择不需要记录辅助</span>
              </div>
            </div>
          </div>
          <!--内容-->
          <div class="answer">
            <!--实时保存状态-->
            <div class="status" :class="{ 'status-show': reportStatus !== '' }">
              <img v-if="reportStatus" :src="noticeIcon[reportStatus]" />
              <span>{{ noticeText[reportStatus] }}</span>
            </div>

            <!--模板1 正确率回合-->
            <correct-rate-round
              :current="current"
              v-if="current.recordingMethod === 'SUCCESS_RATE'"
              :ref="`teachingSkill${current.id}`"
              :id="`teachingSkill${current.id}`"
              @saveClassData="saveClassData"
            ></correct-rate-round>
            <!--模板2 时长-->
            <duration
              :current="current"
              v-if="current.recordingMethod === 'DURATION'"
              :ref="`teachingSkill${current.id}`"
              :id="`teachingSkill${current.id}`"
              @saveClassData="saveClassData"
            ></duration>
            <!--模板3 固定时长次数-->
            <fixed-duration
              :current="current"
              v-if="current.recordingMethod === 'NUMBER_SUCCESS'"
              :ref="`teachingSkill${current.id}`"
              :id="`teachingSkill${current.id}`"
              @saveClassData="saveClassData"
            ></fixed-duration>
            <!--模板4 正确率（步骤）-->
            <correct-rate-steps
              :current="current"
              v-if="current.recordingMethod === 'STEP_SUCCESS_RATE'"
              :ref="`teachingSkill${current.id}`"
              :id="`teachingSkill${current.id}`"
              @saveClassData="saveClassData"
            ></correct-rate-steps>
          </div>
        </div>
      </a-col>
    </a-row>
    <!--行为记录-->
    <a-layout-footer class="recording" v-if="isBehaviorRecord">
      <div class="title" @click="recordStatus = !recordStatus">
        行为记录
        <a-icon type="down" :class="[recordStatus ? 'down' : 'up']" />
      </div>
      <recording
        ref="recording"
        class="recording-content"
        :class="[recordStatus ? 'recording-content-show' : 'recording-content-hide']"
      ></recording>
    </a-layout-footer>
    <!--查看更多-->
    <see-more ref="seeMore" :dataSource="current"></see-more>
    <!-- 失败的信息提示 -->
    <a-modal
      v-model="visibleError"
      width="400px"
      title="信息提示"
      :closable="false"
      :keyboard="false"
      :maskClosable="false"
      :bodyStyle="{ padding: 0 }"
      :ok-button-props="{
        props: { shape: 'round' },
        domProps: {
          innerHTML: '好的',
        },
      }"
      :cancel-button-props="{ props: { shape: 'round' }, style: { display: 'none' } }"
      @ok="handleOk"
    >
      <a-card style="height: 100%; overflow: auto">
        <a-icon type="question-circle" style="color: #faad14; font-size: 20px" />
        当前网络环境异常，无法结束课程，请稍后再试！
      </a-card>
    </a-modal>
  </div>
</template>
<script>
import { cloneDeep } from 'lodash'
import seeMore from './see-more'
import recording from './common-record/common-record'
import correctRateRound from './detail/correct-rate-round'
import duration from './detail/duration'
import fixedDuration from './detail/fixed-duration'
import correctRateSteps from './detail/correct-rate-steps'
import * as Api from '@/api/serviceManager'
import * as studentApi from '@/api/student'
import { convertToChineseNumber } from '@/utils/util'
import { mapActions, mapGetters } from 'vuex'
import mixins from './mixins.js'

export default {
  mixins: [mixins],
  components: {
    recording,
    correctRateRound,
    duration,
    fixedDuration,
    correctRateSteps,
    seeMore,
  },
  data() {
    return {
      iconLoading: false, // 结束课程loading
      recordStatus: true, // 行为记录-false收起、true展开状态
      isBehaviorRecord: true, // 是否需要行为记录-true 显示 false隐藏
      timer: null,
      activeLeft: Number, // 左侧选中索引
      // 左侧选中数据
      current: {
        skillPhaseName: '',
      },
      query: {}, // url参数
      teachingSkillList: [], // 技能包列表
      // 暂存其他技能训练数据
      classTrainingListDTOList: [
        // {
        // classTrainingList,
        // planSkillPhaseId  // 阶段id在当前场景下是唯一的
        // }
      ],
    }
  },
  created() {
    const {
      $route: { query },
    } = this
    this.query = query
    const { isUnfinished = false } = query

    if (isUnfinished) {
      this.initUnfinishedData()
    } else {
      this.initNewCourseData()
    }
    this.activeLeft = 0
  },
  computed: {
    ...mapGetters({
      userInfo: 'getUserInfo',
    }),
  },
  mounted() {
    // 浏览器刷新警告
    window.onbeforeunload = (e) => {
      var message = 'some word'
      e = e || window.event
      if (e) {
        e.returnValue = message
      }
      return '浏览器关闭！'
    }
  },
  methods: {
    ...mapActions(['changeSpinning']),
    // 选择左侧
    handleChooseLeft({ index }) {
      clearInterval(this.timer)
      if (this.activeLeft === index) return

      const { id } = this.teachingSkillList[this.activeLeft]
      const tempChildData = this.$refs[`teachingSkill${id}`].getData({})
      this.$set(this.teachingSkillList, this.activeLeft, tempChildData)

      // 设置空销户组件
      this.current = {}
      this.$nextTick(() => {
        this.activeLeft = index
        this.current = cloneDeep(this.teachingSkillList[index])
        // 左侧技能存在计时器
        if (this.teachingSkillList[index].hasOwnProperty('childData')) {
          delete this.teachingSkillList[index].childData
        }
      })
      // 有计数器开始计时
      this.startTimer()
      // console.log(this.current)
    },
    // 当前正在练习的临时数据
    getActiveSkillPhaseChildData() {
      const { id } = this.teachingSkillList[this.activeLeft]
      const tempChildData = this.$refs[`teachingSkill${id}`].getData({ isClearTime: false })
      this.$set(this.teachingSkillList, this.activeLeft, tempChildData)
    },
    // 开始轮询
    startTimer() {
      this.timer = setInterval(() => {
        const list = cloneDeep(this.teachingSkillList)
        list.map((item, index) => {
          const { childData } = item
          if (childData) {
            const { timeType, seconds, totalSeconds = 0, buttonType } = childData
            // 计时计次
            if (timeType === 'add' && buttonType === 2) {
              item.childData.seconds = seconds + 1
            }
            // 固定时长计次
            if (timeType === 'sub' && seconds > 0 && buttonType === 2) {
              item.childData.seconds = seconds > 1 ? seconds - 1 : 0
            }

            if (timeType === 'sub' && seconds > 0 && buttonType !== 1) {
              item.childData.percentage = ((seconds - 1) / totalSeconds) * 100
            }
          }
        })
        this.teachingSkillList = list
      }, 1000)
    },

    // 保存上课数据
    saveClassData(data) {
      const { classTrainingListDTOList } = this
      // 技能阶段上课结果-按阶段
      const key = classTrainingListDTOList.findIndex((item) => {
        return Number(item.planSkillPhaseId) === Number(data.planSkillPhaseId)
      })
      if (key > -1) {
        classTrainingListDTOList.splice(key, 1, data)
      } else {
        classTrainingListDTOList.push(data)
      }
    },

    // 结束课程
    handleGoResult() {
      const { recordingMethod, id } = this.current
      // 当前选中的 技能
      const _ref = this.$refs[`teachingSkill${id}`]
      const { buttonType } = _ref
      if (
        ['DURATION', 'NUMBER_SUCCESS'].indexOf(recordingMethod) > -1 &&
        _ref &&
        [2, 3].indexOf(Number(buttonType)) > -1
      ) {
        this.$notification['error']({ message: '还有记录时长或记录频率的技能未进行记录结果保存，请处理后再结束课程' })
        return
      }
      // 未选中技能
      for (const item of this.teachingSkillList) {
        const { childData = {}, recordingMethod, id: itemId } = item
        const { buttonType } = childData
        if (
          itemId !== id &&
          ['DURATION', 'NUMBER_SUCCESS'].indexOf(recordingMethod) > -1 &&
          [2, 3].indexOf(Number(buttonType)) > -1
        ) {
          this.$notification['error']({ message: '还有记录时长或记录频率的技能未进行记录结果保存，请处理后再结束课程' })
          return
        }
      }
      // A区是否有未结束
      if (this.isBehaviorRecord && this.$refs.recording.isAllOver()) {
        this.$notification['error']({ message: '请检查【行为记录】是否全部结束' })
        return
      }
      this.iconLoading = true
      const confirm = this.$confirm({
        title: '结束课程',
        content: `结束后本次课程的数据将无法修改，是否确认结束课程`,
        onOk: () => {
          this.closeAutoSave()
          this.save().then(() => {
            this.finishTeaching()
              .then(() => {
                this.$router.push({
                  name: 'homeSchoolTrainingCompleted',
                  query: this.query,
                })
              })
              .catch(() => {
                confirm.destroy()
                this.iconLoading = false
              })
          })
        },
        onCancel: () => {
          this.iconLoading = false
          confirm.destroy()
        },
      })
    },
    // 查看详情
    handleDetail() {
      this.$refs.seeMore.showModal()
    },
    // 显示时间
    showTime({ seconds = 0 }) {
      let minute = '00'
      let second = '00'
      if (seconds < 60) {
        second = this.formatTime(seconds)
      } else {
        minute = this.formatTime(parseInt(seconds / 60))
        second = this.formatTime(parseInt(seconds % 60))
      }
      return `${minute} : ${second}`
    },
    // 格式化数字 往前补0
    formatTime(number) {
      return number < 10 ? `0${number}` : number
    },
    // 初始化-未完成的数据
    initUnfinishedData() {
      const {
        $route: { query },
      } = this
      const { studentId } = query
      const params = { studentId }
      studentApi.continueHistoryClass(params).then((res) => {
        const { code, data } = res
        if (code === 0) {
          const { temporaryData, teachingClassId } = data
          // console.log(JSON.parse(temporaryData))
          const {
            teachingSkillList,
            classTrainingListDTOList,
            classBehaviorRecordList,
            behaviorRecordList,
            activeLeft,
            isBehaviorRecord,
          } = JSON.parse(temporaryData)
          // 左侧数据
          this.teachingSkillList = teachingSkillList
          this.$nextTick(() => {
            // 是否显示行为记录
            this.isBehaviorRecord = isBehaviorRecord
            this.query = { ...this.query, ...{ teachingClassId } }
            // 左侧选中
            this.activeLeft = activeLeft
            // 设置默认选中数据
            this.current = cloneDeep(teachingSkillList[activeLeft])
            this.classTrainingListDTOList = classTrainingListDTOList
            // 显示行为记录
            isBehaviorRecord && this.$refs.recording.initData({ classBehaviorRecordList, behaviorRecordList })
          })
          // 开启自动保存
          this.beginAutoSave()
        }
      })
    },
    // 初始化数据
    initNewCourseData() {
      this.changeSpinning(true)
      Api.getTeachingDetal(this.query)
        .then((res) => {
          this.changeSpinning(false)
          const { code, data } = res
          if (code === 0) {
            // 行为记录、技能阶段、是否需要行为记录
            const { behaviorRecordList, teachingSkillList, isBehaviorRecord } = data
            // 是否显示行为记录
            this.isBehaviorRecord = isBehaviorRecord
            behaviorRecordList.map((item) => {
              const { recordingMethod } = item
              // 行为记录-计次-加次数字段
              if (recordingMethod === 'COUNT') {
                item.count = 0
              }
              // 行为记录-倒计时计次-加次数字段
              if (recordingMethod === 'COUNTDOWN_COUNT') {
                item.setTotalSeconds = 0
                item.isSetMinuteSecond = false
              }

              return item
            })
            isBehaviorRecord && this.$refs.recording.initData({ behaviorRecordList })
            // 技能阶段数据
            this.teachingSkillList = teachingSkillList.map((item) => {
              const {
                trainingAssistanceMode1,
                trainingAssistanceMode2,
                trainingAssistanceMode3,
                material,
                recordingMethod,
              } = item
              // 训练辅助方式PP123
              const tempTrainingAssistanceMode = cloneDeep(
                [trainingAssistanceMode1, trainingAssistanceMode2, trainingAssistanceMode3].filter(Boolean)
              )
              const trainingAssistanceModeList = []
              tempTrainingAssistanceMode &&
                tempTrainingAssistanceMode.forEach((item, index) => {
                  trainingAssistanceModeList.push({
                    id: `PP${index + 1}`,
                    name: item,
                  })
                })

              // console.log(trainingAssistanceModeList)
              item.materialList = []
              material.forEach((item1) => {
                const tempMaterialList = {
                  id: item1.id,
                  name: item1.materialName,
                  // name: item1,
                  resultList: [],
                  timeList: [],
                  // minimumRounds: item.minimumRounds
                }

                // 模板3 固定时长次数
                if (recordingMethod === 'NUMBER_SUCCESS') {
                  // tempMaterialList.seconds = item.minutes * 60 + item.seconds
                  tempMaterialList.frequencyList = []
                  tempMaterialList.count = 0
                }
                // 模板4 正确率（步骤）
                if (recordingMethod === 'STEP_SUCCESS_RATE') {
                  tempMaterialList.stepsDescriptionList = item.stepsDescriptionList
                }

                item.materialList.push(tempMaterialList)
              })

              // 模板1 计时计次
              if (recordingMethod === 'DURATION') {
                item.totalSeconds = item.minutes * 60 + item.seconds
              }

              // 模板3 固定时长次数
              if (recordingMethod === 'NUMBER_SUCCESS') {
                item.seconds = item.minutes * 60 + item.seconds
              }
              // 模板4 正确率（步骤）
              if (recordingMethod === 'STEP_SUCCESS_RATE') {
                delete item.stepsDescriptionList
              }
              return { ...item, ...{ trainingAssistanceModeList } }
            })
            // 设置默认选中
            this.current = this.teachingSkillList[this.activeLeft]
            this.current.activeLeft = this.activeLeft

            // 开启自动保存
            this.beginAutoSave()
          }
        })
        .catch(() => {
          this.changeSpinning(false)
        })
    },
    convertToChineseNumber,
  },

  beforeRouteLeave(to, from, next) {
    if (['login', 'homeSchoolTrainingCompleted'].indexOf(to.name) === -1) {
      const confirm = this.$confirm({
        title: '提示',
        content: `离开页面后当前上课数据将丢失，是否确定离开`,
        onOk: () => {
          confirm.destroy()
          next((vm) => {
            vm.removeOnLineOffLine()
          })
        },
        onCancel: () => {
          confirm.destroy()
        },
      })
    } else {
      next((vm) => {
        vm.removeOnLineOffLine()
      })
    }
  },
  beforeDestroy() {
    window.onbeforeunload = null
    clearInterval(this.timer)
    this.visibleError = false
    // this.removeOnLineOffLine()
  },
}
</script>
<style scoped lang="less">
.classes-begin {
  .left-panel {
    padding-left: 0;
    background: #fff;
    margin-bottom: 0;
    height: 100%;
    overflow-y: auto;
    // 技能
    .skill {
      position: relative;
      margin-bottom: 8px;
      cursor: pointer;
      // 技能头部
      .skill-head {
        height: 36px;
        padding: 8px 0 8px 16px;
        background: #dfe0eb;
        font-size: 14px;
        font-weight: 600;
        color: #333333;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .progress {
        position: absolute;
        left: 0;
        top: 36px;
        right: 0;
        bottom: 0;
        background: #f8a70e;
        height: 48px;
      }
      // 技能内容
      .skill-content {
        position: relative;
        z-index: 10;
        display: flex;
        flex-direction: column;
        background-color: rgba(0, 0, 0, 0);
        border-left: 1px solid #dfe0eb;
        border-right: 1px solid #dfe0eb;
        border-bottom: 1px solid #dfe0eb;
        padding: 4px;
        // 技能名字(顶部颜色)
        .skill-content-top {
          font-size: 12px;
          color: #333333;
          display: flex;
          height: 22px;
          > div {
            flex: 1;
            display: flex;
            align-items: center;
          }
        }
        // 阶段名称
        .skill-content-bottom {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          color: #8c8c8c;
          font-size: 12px;
        }

        // 正计时、倒计时图标加文字
        .clock {
          align-self: center;
          img {
            width: 16px;
          }
          span {
            padding-left: 8px;
            font-weight: 600;
            font-size: 12px;
          }
        }
        .clock-green {
          color: #34ca71;
          span {
            color: #34ca71;
          }
        }
        .clock-black {
          color: #333333;
          span {
            color: #333333;
          }
        }
      }
    }
    .active {
      .skill-head {
        background: #3a5ff8;
        color: #fff;
      }
    }
  }
  // 右侧答题
  .right-panel {
    flex: 1;
    background: #fff;
    overflow-y: auto;
    // 答题顶部
    .title {
      padding: 8px;
      display: flex;
      border-bottom: 10px solid #f7f8fc;
      // 左侧学员、老师
      .title-l {
        // width: 167px;
        display: flex;
        font-size: 14px;
        color: #333333;
        .title-l-l {
          align-items: center;
          display: flex;
          img {
            align-self: center;
            width: 40px;
            height: 40px;
            margin-right: 16px;
          }

          .title-l-l-r {
            width: 126px;
            display: flex;
            flex-direction: column;
          }
        }
      }
      // 右侧 阶段名称、辅助方式、按钮
      .title-r {
        flex: 1;
        display: flex;
        flex-direction: column;
        .title-r-t {
          display: flex;
          justify-content: space-between;
          /*margin-bottom: 8px;*/
          .title-r-t-phase {
            flex: 1;
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
            .label {
              width: 70px;
            }
            .name {
              width: 126px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            .more {
              width: 70px;
              cursor: pointer;
              color: #3a5ff8;
              padding-left: 5px;
            }
          }
          .title-r-t-btn {
            width: 208px;
            .temp-btn {
              background-color: #52be7f;
              border-color: #52be7f;
            }
            button {
              padding: 0;
              width: 100px;
            }
          }
        }

        .title-r-b {
          display: flex;
          align-items: center;
          .assist-method-item {
            span {
              display: inline-block;
            }
            .number {
              /*padding: 4px 0;*/
              font-size: 12px;
              width: 36px;
              text-align: center;
              background: #e1e8ff;
              border-radius: 2px;
              color: #3a5ff8;
              font-weight: 600;
              margin-right: 6px;
            }
            .number-name {
              font-size: 14px;
              color: #1a1a1a;
              margin-right: 18px;
            }
          }
        }
      }
      .label {
        font-weight: 600;
        font-size: 14px;
        color: #333333;
      }
    }
    .answer {
      padding: 0 10px;
      overflow-y: auto;
      .status {
        padding-top: 10px;
        display: flex;
        align-items: center;
        cursor: pointer;
        img {
          width: 19px;
          height: 19px;
          margin-right: 7px;
          border-radius: 50%;
          border: 0;
        }
        span {
          font-size: 14px;
          font-weight: 400;
          color: #666666;
        }
      }

      .status-show {
        opacity: 1;
      }
    }
  }

  .left-panel,
  .right-panel {
    height: calc(100vh - 206px);
    transition: all 0.5s;
  }

  .left-panel-long,
  .right-panel-long {
    height: calc(100vh - 92px);
    transition: all 0.5s;
  }

  /*行为记录*/
  .recording {
    background: #fff;
    border: 1px solid #c8d4ff;
    /*标题*/
    .title {
      font-size: 14px;
      color: #333;
      line-height: 30px;
      text-align: center;
      cursor: pointer;
      /*箭头*/
      .up {
        transform: rotate(-180deg);
        transition: all 0.3s;
      }

      .down {
        transition: all 0.3s;
      }
    }
    /*行为记录-内容*/
    .recording-content {
      padding: 10px 12px 12px 12px;
      overflow: hidden;
      transition: all 0.5s;
    }

    .recording-content-show {
      height: auto;
    }

    .recording-content-hide {
      height: 0px;
      padding: 0px 24px 0;
    }
  }
}
/*定义滚动条高宽及背景
 高宽分别对应横竖滚动条的尺寸*/
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  background-color: rgba(0, 0, 0, 0.08);
}
/*定义滚动条轨道
 内阴影+圆角*/
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  background-color: rgba(0, 0, 0, 0);
}
/*定义滑块
 内阴影+圆角*/
::-webkit-scrollbar-thumb {
  border-radius: 16px;
  -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0.08);
  background-color: rgba(144, 147, 153, 0.3);
}
</style>
