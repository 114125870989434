<!-- 家校训练服务-上课-阶段-查看更多 -->
<template>
  <a-modal
    v-model="visible"
    title="详情"
    width="716px"
    :destroyOnClose="true"
    :bodyStyle="{ padding: 0 }"
    :ok-button-props="{ props: { shape: 'round' } }"
    :cancel-button-props="{ props: { shape: 'round' } }"
    @ok="handleOk"
    @cancel="handleOk"
  >
    <a-card style="height: 100%; overflow: auto">
      <h3>阶段目标</h3>
      <div style="line-height: 2.5; color: #8c8c8c">{{ dataSource.goal }}</div>
      <h3>注意事项</h3>
      <div v-if="dataSource.precautions && dataSource.precautions.indexOf('\n')">
        <p
          v-for="(item, index) in dataSource.precautions.split('\n')"
          :key="index"
          class="descriptions-content text-color-secondary"
        >
          {{ item }}
        </p>
      </div>
      <p v-else class="descriptions-content text-color-secondary">{{ dataSource.precautions }}</p>
    </a-card>
  </a-modal>
</template>

<script>
export default {
  props: {
    dataSource: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      visible: false,
      name: ''
    }
  },
  methods: {
    showModal(callback) {
      this.visible = true
    },

    handleOk() {
      this.visible = false
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .ant-modal-body {
  height: calc(100vh - 200px - 110px);
  overflow-y: auto;
}

/*定义滚动条高宽及背景
高宽分别对应横竖滚动条的尺寸*/
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  background-color: rgba(0, 0, 0, 0.08);
}
/*定义滚动条轨道
 内阴影+圆角*/
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  background-color: rgba(0, 0, 0, 0);
}
/*定义滑块
 内阴影+圆角*/
::-webkit-scrollbar-thumb {
  border-radius: 16px;
  -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0.08);
  background-color: rgba(144, 147, 153, 0.3);
}
</style>
