<!-- 家校训练服务-上课-底部(行为记录) -->
<template>
  <div>
    <a-row class="recording-item" :gutter="10">
      <a-col :span="4" v-for="(item, index) in behaviorRecordList" :key="index">
        <a-card style="width: 100%">
          <!--计时-->
          <timing
            v-if="item.recordingMethod == 'TIMING'"
            :ref="item.id"
            :data="item"
            :index="index"
            :temp-list="classBehaviorRecordList"
            @finish="handleChooseRecord"
          ></timing>
          <!--计次-->
          <count
            v-if="item.recordingMethod == 'COUNT'"
            :ref="item.id"
            :data="item"
            :index="index"
            @getChildData="getChildData"
          ></count>
          <!--计时计次-->
          <timingCount
            v-if="item.recordingMethod == 'TIMING_COUNT'"
            :ref="item.id"
            :data="item"
            :index="index"
            :temp-list="classBehaviorRecordList"
            @finish="handleChooseRecord"
          ></timingCount>
          <!--固定时长计次-->
          <countdown
            v-if="item.recordingMethod == 'COUNTDOWN_COUNT'"
            :ref="item.id"
            :data="item"
            :index="index"
            :temp-list="classBehaviorRecordList"
            @getSumTime="getSumTime"
            @finish="handleChooseRecord"
          ></countdown>
        </a-card>
      </a-col>
    </a-row>
  </div>
</template>
<script>
import timing from './timing'
import count from './count'
import timingCount from './timing-count'
import countdown from './countdown'
import { mapActions } from 'vuex'

export default {
  components: {
    // 计时
    timing,
    // 计次
    count,
    // 计时计次
    timingCount,
    // 固定时长计次
    countdown,
  },
  data() {
    return {
      // 行为列表
      behaviorRecordList: [],
      // 给后台数据(除计次外，每次操作都会加一条)
      classBehaviorRecordList: [],
    }
  },
  methods: {
    ...mapActions(['changeSpinning']),
    // 设置固定时长计次-秒数
    getSumTime(data) {
      const { id, totalSeconds, recordingMethod, isSetMinuteSecond, setTotalSeconds } = data
      const key = this.behaviorRecordList.findIndex((item) => {
        return item.id === id
      })
      if (key > -1) {
        if (recordingMethod === 'COUNTDOWN_COUNT') {
          this.$set(
            this.behaviorRecordList,
            key,
            Object.assign({}, this.behaviorRecordList[key], { totalSeconds, isSetMinuteSecond, setTotalSeconds })
          )
        } else {
          this.$set(this.behaviorRecordList, key, Object.assign({}, this.behaviorRecordList[key], { totalSeconds }))
        }
      }
    },
    // 子组件数据
    getChildData(data) {
      const { id: behaviorRecordId, recordingMethod, duration, frequency } = data
      // 是否为计次
      const key = this.classBehaviorRecordList.findIndex((item) => {
        return item.behaviorRecordId === behaviorRecordId && recordingMethod === 'COUNT'
      })
      // 计次替换数据,其他3种追加
      if (key > -1) {
        // 为了保存计次的id
        const tempItem = this.classBehaviorRecordList[key]
        this.$set(this.classBehaviorRecordList, key, { ...tempItem, ...{ behaviorRecordId, duration, frequency } })
      } else {
        this.classBehaviorRecordList.push({ behaviorRecordId, duration, frequency })
      }

      this.saveClassTraining()
    },
    // 初始化数据（父组件调用）
    initData({ behaviorRecordList, classBehaviorRecordList = [] }) {
      this.behaviorRecordList = behaviorRecordList
      this.classBehaviorRecordList = classBehaviorRecordList
    },
    // 选择行为记录
    handleChooseRecord(data) {
      const {
        item,
        item: { id, recordingMethod },
        index,
        duration,
        frequency,
      } = data
      this.$set(this.behaviorRecordList, index, Object.assign({}, item))
      // 子组件点结束
      if (duration) {
        this.getChildData({ id, duration, frequency, recordingMethod })
      }
    },
    // 保存训练时间
    saveClassTraining() {
      return new Promise((resolve, reject) => {
        const { behaviorRecordList, classBehaviorRecordList } = this
        const params = {
          behaviorRecordList,
          classBehaviorRecordList,
        }
        resolve(params)
        reject()
      })
    },
    //  是否全部结束
    isAllOver() {
      for (const item of this.behaviorRecordList) {
        const { recordingMethod, id } = item
        if (recordingMethod !== 'COUNT' && this.$refs[id][0] && !this.$refs[id][0].btnFlag) {
          return true
        }
      }
      return false
    },
    // 覆盖上课记录
    // setClassBehaviorRecordList({ classBehaviorRecordList }) {
    //   this.classBehaviorRecordList = classBehaviorRecordList
    // }
  },
}
</script>
<style scoped lang="less">
.recording-item {
  display: flex;
}

/deep/ .ant-card-body {
  padding: 5px;
  height: 100px;
  border: 1px solid #e1e8ff;
}
</style>
